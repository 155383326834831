import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EarmarkDialog from './EarmarkDialog';
import TransactionListItem from './TransactionListItem';
import { Bookmark, Plus } from '../Icons';
import accountProp from '../../propTypes/account';
import { createEarmark, updateEarmark, deleteEarmark, fetchAccountEarmarks } from '../../requests/earmarks';
import { fetchClearedAccountTransactions, fetchPendingAccountTransactions } from '../../requests/transactions';
import { formatShortDate } from '../../utils/format';

const TransactionList = ({
  account,
  fetchAccountEarmarks,
  fetchClearedAccountTransactions,
  fetchPendingAccountTransactions
}) => {
  const [pendingTransactions, setPendingTransactions] = useState([]);
  const [clearedTransactions, setClearedTransactions] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const [earmarks, setEarmarks] = useState([]);
  const [showEarmarks, setShowEarmarks] = useState(false);

  useEffect(async () => {
    const items = await fetchPendingAccountTransactions(account);
    setPendingTransactions(items);
  }, [account.id]);

  useEffect(async () => {
    const { items, total_count: totalCount } = await fetchClearedAccountTransactions(account, page);
    setHasMore(totalCount > clearedTransactions.length + items.length);
    setClearedTransactions(clearedTransactions.concat(items));
  }, [account.id, page]);

  useEffect(async () => {
    const earmarks = await fetchAccountEarmarks(account);
    setEarmarks(earmarks);
  }, [account.id]);

  const nextPage = useCallback(() => setPage(page + 1));

  const handleRemoveEarmark = async (earmark) => {
    if (window.confirm('Are you sure you want to delete this earmark?')) {
      await deleteEarmark(earmark);
      const earmarks = await fetchAccountEarmarks(account);
      setEarmarks(earmarks);
    }
  };

  const handleUpdateEarmark = async (earmark) => {
    const { amount, payee, category_id: categoryId } = earmark;
    const params = { amount, payee, category_id: categoryId };

    if (earmark.id) {
      await updateEarmark(earmark.id, params);
    } else {
      await createEarmark(params);
    }

    const earmarks = await fetchAccountEarmarks(account);
    setEarmarks(earmarks);
  };

  const groupedPendingTransactions = pendingTransactions.reduce((acc, transaction) => {
    return {
      ...acc,
      [transaction.date]: [
        ...(acc[transaction.date] || []),
        <TransactionListItem key={transaction.id} account={account} transaction={transaction} />
      ]
    };
  }, {});

  const groupedClearedTransactions = clearedTransactions.reduce((acc, transaction) => {
    return {
      ...acc,
      [transaction.date]: [
        ...(acc[transaction.date] || []),
        <TransactionListItem key={transaction.id} account={account} transaction={transaction} />
      ]
    };
  }, {});

  return (
    <>
      <div className="flex">
        <div className="flex-grow-0">
          <h2 className="text-2xl text-gray-700 pb-2">
            {pendingTransactions.length > 0 ? 'Pending Transactions' : 'Transactions'}
          </h2>
        </div>

        <div className="flex-1">
          {earmarks.length === 0 && (
            <button
              type="button"
              onClick={() => setShowEarmarks(true)}
              className="text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-0 mt-1 ml-2"
            >
              <Bookmark className="w-6 h-6" />
            </button>
          )}

          {earmarks.length > 0 && (
            <button
              type="button"
              onClick={() => setShowEarmarks(true)}
              className="rounded-full py-1 px-3 bg-gray-100 hover:bg-gray-200 ml-1"
            >
              {earmarks.length}
              <Bookmark className="inline w-6 h-6 pl-1" />
            </button>
          )}
        </div>

        <div className="flex-grow-0">
          <Link
            to={`/accounts/${account.id}/transactions/new`}
            className="primary-button px-2 py-1 mr-2 flex items-center justify-center"
          >
            <Plus className="inline h-6 w-6" />
          </Link>
        </div>
      </div>

      {pendingTransactions.length === 0 && clearedTransactions.length === 0 && (
        <p className="empty">No transactions.</p>
      )}

      {Object.keys(groupedPendingTransactions).reduce(
        (acc, date) => [
          ...acc,
          <div key={date} className="date-divider">
            {formatShortDate(date)}
          </div>,
          ...groupedPendingTransactions[date]
        ],
        []
      )}

      {pendingTransactions.length > 0 && clearedTransactions.length > 0 && (
        <h2 className="mt-10 text-2xl text-gray-700 pb-2">Cleared Transactions</h2>
      )}

      {Object.keys(groupedClearedTransactions).reduce(
        (acc, date) => [
          ...acc,
          <div key={date} className="date-divider">
            {formatShortDate(date)}
          </div>,
          ...groupedClearedTransactions[date]
        ],
        []
      )}

      {hasMore && (
        <div className="mt-9 mb-9 text-center">
          <button type="button" className="button" onClick={nextPage}>
            Load More
          </button>
        </div>
      )}

      {showEarmarks && (
        <EarmarkDialog
          account={account}
          earmarks={earmarks}
          onClose={() => setShowEarmarks(false)}
          onUpdate={handleUpdateEarmark}
          onRemove={handleRemoveEarmark}
        />
      )}
    </>
  );
};

TransactionList.propTypes = {
  account: accountProp.isRequired,
  fetchAccountEarmarks: PropTypes.func.isRequired,
  fetchClearedAccountTransactions: PropTypes.func.isRequired,
  fetchPendingAccountTransactions: PropTypes.func.isRequired
};

TransactionList.defaultProps = {
  fetchAccountEarmarks,
  fetchClearedAccountTransactions,
  fetchPendingAccountTransactions
};

export default TransactionList;
