import axios from 'axios';

export async function fetchPendingCategorySplits(category) {
  const { data: splits } = await axios.get(`/api/categories/${category.id}/splits/pending`);

  return splits;
}

export async function fetchClearedCategorySplits(category, page) {
  const { data: splits } = await axios.get(`/api/categories/${category.id}/splits/cleared`, {
    params: { page }
  });

  return splits;
}
