import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import AppContext from '../../AppContext';
import LoginForm from '../../components/LoginForm';
import Navbar from '../../components/Navbar';
import { setAuthToken } from '../../localStorage';
import { login } from '../../requests/login';

const Login = () => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [invalidLogin, setInvalidLogin] = useState(false);
  const { refreshContext } = useContext(AppContext);

  const handleSubmit = async (email, password) => {
    setInvalidLogin(false);

    try {
      const { token } = await login(email, password);
      setAuthToken(token);

      await refreshContext();
      setLoggedIn(true);
    } catch (error) {
      setInvalidLogin(true);
    }
  };

  if (loggedIn) {
    return <Redirect to="/" />;
  }

  return (
    <>
      <Navbar />

      <div className="container">
        <div className="pt-4">
          <div className="w-full sm:w-8/12 md:w-6/12 lg:w-4/12 m-auto">
            <LoginForm onSubmit={handleSubmit} invalidLogin={invalidLogin} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
