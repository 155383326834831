import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import AppContext from '../../AppContext';
import AccountHeader from '../../components/AccountHeader';
import CategoryList from '../../components/CategoryList';
import Navbar from '../../components/Navbar';
import TransactionForm from '../../components/TransactionForm';
import withAccount from '../../components/withAccount';
import accountProp from '../../propTypes/account';
import { fetchEarmark } from '../../requests/earmarks';
import { createTransaction } from '../../requests/transactions';
import { formatISODate } from '../../utils/format';

function NewTransaction({ account }) {
  const { refreshContext } = useContext(AppContext);
  const query = new URLSearchParams(useLocation().search);
  const earmarkId = query.get('earmark_id');

  const [cancelled, setCancelled] = useState(false);
  const [errors, setErrors] = useState({});
  const [transaction, setTransaction] = useState({
    type: 'payment',
    date: formatISODate(new Date()),
    amount: '',
    from_account_id: account.id,
    splits: [{ type: 'from' }, { type: 'to' }],
    payee: '',
    memo: ''
  });

  useEffect(async () => {
    if (earmarkId) {
      const earmark = await fetchEarmark(earmarkId);

      const changes = {
        earmark_id: earmarkId,
        payee: earmark.payee,
        amount: earmark.amount,
        splits: [{ type: 'from', category_id: earmark.category_id }]
      };

      setTransaction({ ...transaction, ...changes });
    }
  }, [earmarkId]);

  if (cancelled) {
    return <Redirect to={`/accounts/${account.id}/transactions`} />;
  }

  const handleChange = (changes) => setTransaction({ ...transaction, ...changes });
  const handleCancel = () => setCancelled(true);

  const handleSubmit = async (data) => {
    try {
      await createTransaction(data);
      await refreshContext();
      setCancelled(true);
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  return (
    <>
      <Navbar account={account} />

      <AccountHeader account={account}>{(account) => `/accounts/${account.id}/transactions`}</AccountHeader>

      <hr />

      <div className="container">
        <div className="layout">
          <div>
            <CategoryList key={account.id} account={account}>
              {(category) => `/accounts/${account.id}/categories/${category.id}/transactions`}
            </CategoryList>
          </div>

          <div>
            <TransactionForm
              key={account.id}
              account={account}
              transaction={transaction}
              errors={errors}
              onChange={handleChange}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </div>
        </div>
      </div>
    </>
  );
}

NewTransaction.propTypes = {
  account: accountProp.isRequired
};

export default withAccount(NewTransaction);
