import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import AppContext from '../../AppContext';
import AccountHeader from '../../components/AccountHeader';
import CategoryForm from '../../components/CategoryForm';
import CategoryList from '../../components/CategoryList';
import Navbar from '../../components/Navbar';
import withAccount from '../../components/withAccount';
import withCategory from '../../components/withCategory';
import accountProp from '../../propTypes/account';
import categoryProp from '../../propTypes/category';
import { updateCategory, deleteCategory } from '../../requests/categories';

const EditCategory = ({ account, category }) => {
  const [deleted, setDeleted] = useState(false);
  const [errors, setErrors] = useState({});
  const { refreshContext } = useContext(AppContext);

  const handleCancel = () => setErrors({});

  const handleSubmit = async (data) => {
    try {
      await updateCategory(category.id, data);
      await refreshContext();
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this category?')) {
      await deleteCategory(category);
      await refreshContext();
      setDeleted(true);
    }
  };

  if (deleted) {
    return <Redirect to={`/accounts/${account.id}`} />;
  }

  return (
    <>
      <Navbar account={account} />

      <AccountHeader account={account}>{(account) => `/accounts/${account.id}`}</AccountHeader>

      <hr />

      <div className="container">
        <div className="layout">
          <div>
            <CategoryList key={account.id} account={account}>
              {(category) => `/accounts/${account.id}/categories/${category.id}`}
            </CategoryList>
          </div>

          <div>
            <CategoryForm
              key={category.id}
              account={account}
              category={category}
              errors={errors}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              onDelete={handleDelete}
            />
          </div>
        </div>
      </div>
    </>
  );
};

EditCategory.propTypes = {
  account: accountProp.isRequired,
  category: categoryProp.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  })
};

export default withAccount(withCategory(EditCategory), 'accountId');
