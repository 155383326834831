import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import categoryProp from '../../propTypes/category';

export const categoryToString = (category) => {
  if (category) {
    return category.name;
  }

  return '';
};

const CategoryMenuItem = ({ category, index, highlightedIndex, getItemProps }) => (
  <div
    className={classNames('menu-item', { highlighted: index === highlightedIndex })}
    {...getItemProps({
      key: `category-${category.id}`,
      item: category,
      index
    })}
  >
    {categoryToString(category)}
  </div>
);

CategoryMenuItem.propTypes = {
  category: categoryProp,
  index: PropTypes.number.isRequired,
  highlightedIndex: PropTypes.number,
  getItemProps: PropTypes.func.isRequired
};

export default CategoryMenuItem;
