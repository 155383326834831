import PropTypes from 'prop-types';

const category = PropTypes.shape({
  id: PropTypes.number,
  account_id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  balance: PropTypes.number
});

export default category;
