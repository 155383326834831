import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import EarmarksForm from './EarmarksForm';
import AmountInput from '../AmountInput';
import CategoryCombobox from '../CategoryCombobox';
import Error from '../Error';
import { Bookmark, X } from '../Icons';
import categoryProp from '../../propTypes/category';

const BudgetCategoryForm = ({ category, categories, budgetCategory, onChange, onRemove, single, errors }) => {
  const { amount, budget_earmarks: budgetEarmarks } = budgetCategory;
  const [showEarmarks, setShowEarmarks] = useState(false);

  const handleCategoryChange = (category) => onChange({ ...budgetCategory, category_id: category.id });

  const handleAmountChange = (amount) => onChange({ ...budgetCategory, amount });

  const handleAddEarmark = () => onChange({ ...budgetCategory, budget_earmarks: [...budgetEarmarks, { payee: '' }] });

  const handleRemoveEarmark = (index) =>
    onChange({
      ...budgetCategory,
      budget_earmarks: [...budgetEarmarks.slice(0, index), ...budgetEarmarks.slice(index + 1)]
    });

  const handleUpdateEarmark = (index, changes) => {
    onChange({
      ...budgetCategory,
      budget_earmarks: [
        ...budgetEarmarks.slice(0, index),
        { ...budgetEarmarks[index], ...changes },
        ...budgetEarmarks.slice(index + 1)
      ]
    });
  };

  const setCategoryAmountFromEarmarks = () => {
    const total = budgetEarmarks.reduce((sum, { amount }) => sum + amount, 0);
    handleAmountChange(total);
    setShowEarmarks(false);
  };

  return (
    <div className="grid grid-cols-3 gap-2 sm:gap-6 mb-6 sm:mb-3">
      {showEarmarks && (
        <EarmarksForm
          category={category}
          earmarks={budgetEarmarks}
          errors={errors.budget_earmarks || []}
          onAdd={handleAddEarmark}
          onRemove={handleRemoveEarmark}
          onUpdate={handleUpdateEarmark}
          onClose={() => setShowEarmarks(false)}
          onApply={() => setCategoryAmountFromEarmarks()}
        />
      )}

      <div className="col-span-3 sm:col-span-2">
        <CategoryCombobox
          categories={categories}
          category={category}
          onChange={handleCategoryChange}
          errors={errors.category_id}
        />

        <Error errors={errors.category_id} prefix="Category" />
      </div>

      <div className="col-span-3 sm:col-span-1 flex">
        <div className="flex-1">
          <AmountInput amount={amount} onChange={handleAmountChange} errors={errors.amount} />

          <Error errors={errors.amount} prefix="Amount" />
        </div>

        {category && (
          <div className="flex-grow-0">
            <button
              type="button"
              onClick={() => setShowEarmarks(true)}
              className={classNames('icon-button', { 'icon-error': errors.budget_earmarks })}
            >
              <Bookmark className={classNames('w-6 h-6', { 'fill-current': budgetEarmarks.length > 0 })} />
            </button>
          </div>
        )}

        {!single && (
          <div className="flex-grow-0">
            <button type="button" onClick={onRemove} className="icon-button">
              <X className="w-6 h-6" />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

BudgetCategoryForm.propTypes = {
  category: categoryProp,
  categories: PropTypes.arrayOf(categoryProp).isRequired,
  budgetCategory: PropTypes.shape({
    category_id: PropTypes.number,
    amount: PropTypes.number,
    budget_earmarks: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        payee: PropTypes.string,
        amount: PropTypes.number
      })
    )
  }),
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  single: PropTypes.bool,
  errors: PropTypes.object.isRequired
};

export default BudgetCategoryForm;
