import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import AppContext from '../../AppContext';
import AccountHeader from '../../components/AccountHeader';
import CategoryList from '../../components/CategoryList';
import Navbar from '../../components/Navbar';
import TransactionForm from '../../components/TransactionForm';
import withAccount from '../../components/withAccount';
import accountProp from '../../propTypes/account';
import { fetchTransaction, updateTransaction, deleteTransaction } from '../../requests/transactions';

const EditTransaction = ({ account, match: { params } }) => {
  const { refreshContext } = useContext(AppContext);
  const [cancelled, setCancelled] = useState(false);
  const [errors, setErrors] = useState({});
  const [transaction, setTransaction] = useState();

  useEffect(async () => {
    const transaction = await fetchTransaction(params.id);
    setTransaction(transaction);
  }, [params.id]);

  if (!transaction) {
    return null;
  }

  if (cancelled) {
    return <Redirect to={`/accounts/${account.id}/transactions`} />;
  }

  const handleChange = (changes) => setTransaction({ ...transaction, ...changes });
  const handleCancel = () => setCancelled(true);

  const handleSubmit = async (data) => {
    try {
      await updateTransaction(transaction.id, data);
      await refreshContext();
      setCancelled(true);
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this transaction?')) {
      await deleteTransaction(transaction);
      await refreshContext();
      setCancelled(true);
    }
  };

  return (
    <>
      <Navbar account={account} />

      <AccountHeader account={account}>{(account) => `/accounts/${account.id}/transactions`}</AccountHeader>

      <hr />

      <div className="container">
        <div className="layout">
          <div>
            <CategoryList key={account.id} account={account}>
              {(category) => `/accounts/${account.id}/categories/${category.id}/transactions`}
            </CategoryList>
          </div>

          <div>
            <TransactionForm
              key={transaction.id}
              account={account}
              transaction={transaction}
              errors={errors}
              onChange={handleChange}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              onDelete={handleDelete}
            />
          </div>
        </div>
      </div>
    </>
  );
};

EditTransaction.propTypes = {
  account: accountProp.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
};

export default withAccount(EditTransaction, 'accountId');
