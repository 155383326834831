import React from 'react';
import AccountHeader from '../../components/AccountHeader';
import CategoryList from '../../components/CategoryList';
import Navbar from '../../components/Navbar';
import TransactionList from '../../components/TransactionList';
import withAccount from '../../components/withAccount';
import accountProp from '../../propTypes/account';

const AccountTransactions = ({ account }) => (
  <>
    <Navbar account={account} />

    <AccountHeader account={account}>{(account) => `/accounts/${account.id}/transactions`}</AccountHeader>

    <hr />

    <div className="container">
      <div className="layout">
        <div>
          <CategoryList key={account.id} account={account}>
            {(category) => `/accounts/${account.id}/categories/${category.id}/transactions`}
          </CategoryList>
        </div>

        <div>
          <TransactionList key={account.id} account={account} />
        </div>
      </div>
    </div>
  </>
);

AccountTransactions.propTypes = {
  account: accountProp.isRequired
};

export default withAccount(AccountTransactions);
