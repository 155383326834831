import PropTypes from 'prop-types';

const earmark = PropTypes.shape({
  id: PropTypes.number,
  category_id: PropTypes.number,
  payee: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired
});

export default earmark;
