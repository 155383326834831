import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { getAuthToken } from './localStorage';
import App from './views/App';
import './tailwind/dist/tailwind.css';

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = 'https://expenditure-api.avaughan.com';
} else {
  axios.defaults.baseURL = 'http://localhost:4000';
}

axios.defaults.headers.common['Accept'] = 'application/json';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

axios.interceptors.request.use((request) => {
  const token = getAuthToken();

  if (token) {
    request.headers.common.Authorization = `Token token="${token}"`;
  }

  return request;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      location.assign('/login');
    }
    return Promise.reject(error);
  }
);

ReactDOM.render(<App />, document.getElementById('root'));
