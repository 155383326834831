import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import Currency from '../Currency';
import { Cash, Pencil, X } from '../Icons';
import accountProp from '../../propTypes/account';
import categoryProp from '../../propTypes/category';
import earmarkProp from '../../propTypes/earmark';

const EarmarkList = ({ account, categories, earmarks, onEdit, onRemove }) => {
  const [createTransaction, setCreateTransaction] = useState();

  if (createTransaction) {
    return <Redirect to={`/accounts/${account.id}/transactions/new?earmark_id=${createTransaction.id}`} />;
  }

  return (
    <div>
      {earmarks.map((earmark) => (
        <div key={earmark.id} className="flex">
          <div className="flex-grow">
            <div className="grid grid-cols-5 gap-4">
              <div className="col-span-2 truncate">{earmark.payee}</div>

              <div className="col-span-2 truncate">
                {categories.find((category) => category.id === earmark.category_id).name}
              </div>

              <div className="text-right">
                <Currency amount={earmark.amount} />
              </div>
            </div>
          </div>

          <div className="flex-none">
            <button type="button" className="icon-button my-0" onClick={() => setCreateTransaction(earmark)}>
              <Cash className="w-6 h-6" />
            </button>

            <button type="button" className="icon-button my-0" data-testid="edit" onClick={() => onEdit(earmark)}>
              <Pencil className="w-6 h-6" />
            </button>

            <button type="button" className="icon-button my-0" data-testid="delete" onClick={() => onRemove(earmark)}>
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

EarmarkList.propTypes = {
  account: accountProp.isRequired,
  categories: PropTypes.arrayOf(categoryProp).isRequired,
  earmarks: PropTypes.arrayOf(earmarkProp).isRequired,
  onEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

export default EarmarkList;
