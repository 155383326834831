import axios from 'axios';

export async function fetchTransaction(id) {
  const { data: transaction } = await axios.get(`/api/transactions/${id}`);

  return transaction;
}

export async function fetchPendingAccountTransactions(account) {
  const { data: transactions } = await axios.get(`/api/accounts/${account.id}/transactions/pending`);

  return transactions;
}

export async function fetchClearedAccountTransactions(account, page) {
  const { data: transactions } = await axios.get(`/api/accounts/${account.id}/transactions/cleared`, {
    params: { page }
  });

  return transactions;
}

export async function createTransaction(params) {
  const { data: transaction } = await axios.post('/api/transactions', { transaction: params });

  return transaction;
}

export async function updateTransaction(id, params) {
  const { data: transaction } = await axios.put(`/api/transactions/${id}`, { transaction: params });

  return transaction;
}

export async function deleteTransaction(transaction) {
  await axios.delete(`/api/transactions/${transaction.id}`);
}

export async function fetchPayees(filter) {
  const { data: payees } = await axios.get(`/api/payees`, { params: { filter } });

  return payees;
}
