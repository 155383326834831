import PropTypes from 'prop-types';

const budget = PropTypes.shape({
  id: PropTypes.number.isRequired,
  account_id: PropTypes.number,
  start_date: PropTypes.string.isRequired,
  end_date: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  budget_categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      category_id: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired
    })
  )
});

export default budget;
