import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import AccountMenuItem from './AccountMenuItem';
import Currency from '../Currency';
import { ChevronDown, X } from '../Icons';
import withAccounts from '../withAccounts';
import accountProp from '../../propTypes/account';

const AccountHeader = ({ account, accounts, children }) => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const toggle = () => setOpen(!open);

  return (
    <div className="container">
      <div className="flex">
        <div className="flex-grow-0">
          <p className="text-base lg:text-lg text-gray-500">{account.bank}</p>
        </div>

        <div className="flex-1 text-right hidden sm:block">
          <p className="text-base lg:text-lg text-gray-500">Current Balance</p>
        </div>
      </div>

      <div className="flex">
        <div className="flex-grow-0">
          <div className="-mt-1">
            <Link
              to={children(account)}
              className="text-3xl font-light lg:text-4xl text-gray-700 pr-2 truncate"
              onClick={close}
            >
              {account.name}
            </Link>
          </div>
        </div>

        <div className="flex-grow-0">
          <div className="-mt-1">
            <button
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded={open}
              onClick={toggle}
            >
              <span className="sr-only">Open account menu</span>

              <ChevronDown className={`${open ? 'hidden' : 'block'} h-6 w-6`} aria-hidden={open} />

              <X className={`${open ? 'block' : 'hidden'} h-6 w-6`} aria-hidden={!open} />
            </button>
          </div>
        </div>

        <div className="flex-1 text-right hidden sm:block">
          <div className="-mt-1">
            <Currency amount={account.balance} className="text-3xl font-light lg:text-4xl text-gray-700" />
          </div>
        </div>
      </div>

      <div className="relative">
        <div
          className={`${
            open ? 'block' : 'hidden'
          } w-full lg:absolute lg:w-3/4 lg:rounded-md lg:shadow-lg bg-white lg:ring-1 lg:ring-black lg:ring-opacity-5`}
        >
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {accounts
              .filter(({ id }) => id !== account.id)
              .map((account) => (
                <AccountMenuItem key={account.id} account={account} url={children(account)} onClick={close} />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

AccountHeader.propTypes = {
  account: accountProp.isRequired,
  accounts: PropTypes.arrayOf(accountProp).isRequired,
  children: PropTypes.func.isRequired
};

export default withAccounts(AccountHeader);
