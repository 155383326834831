import React from 'react';
import Currency from '../Currency';
import splitProp from '../../propTypes/split';

const SplitAmount = ({ split }) => {
  if (split.type === 'from') {
    return <Currency amount={split.amount * -1} />;
  }

  return <Currency amount={split.amount} />;
};

SplitAmount.propTypes = {
  split: splitProp.isRequired
};

export default SplitAmount;
