import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import AccountHeader from '../../components/AccountHeader';
import BudgetForm from '../../components/BudgetForm';
import Navbar from '../../components/Navbar';
import withAccount from '../../components/withAccount';
import accountProp from '../../propTypes/account';
import { fetchBudget, updateBudget, deleteBudget } from '../../requests/budgets';

const EditBudget = ({ account, match: { params } }) => {
  const [budget, setBudget] = useState();
  const [cancelled, setCancelled] = useState(false);
  const [copied, setCopied] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(async () => {
    const budget = await fetchBudget(params.id);
    setBudget(budget);
  }, [params.id]);

  if (!budget) {
    return null;
  }

  if (cancelled) {
    return <Redirect to={`/accounts/${account.id}/budgets`} />;
  }

  if (copied) {
    return <Redirect to={`/accounts/${account.id}/budgets/new?copy=${budget.id}`} />;
  }

  const handleChange = (changes) => setBudget({ ...budget, ...changes });
  const handleCancel = () => setCancelled(true);
  const handleCopy = () => setCopied(true);

  const handleSubmit = async (data) => {
    try {
      await updateBudget(budget.id, data);
      setCancelled(true);
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this budget?')) {
      await deleteBudget(budget);
      setCancelled(true);
    }
  };

  return (
    <>
      <Navbar account={account} />

      <AccountHeader account={account}>{(account) => `/accounts/${account.id}/budgets`}</AccountHeader>

      <hr />

      <div className="container">
        <div className="pt-4">
          <BudgetForm
            key={budget.id}
            account={account}
            budget={budget}
            errors={errors}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onCopy={handleCopy}
            onDelete={handleDelete}
          />
        </div>
      </div>
    </>
  );
};

EditBudget.propTypes = {
  account: accountProp.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      accountId: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired
    })
  })
};

export default withAccount(EditBudget, 'accountId');
