import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SplitForm from './SplitForm';
import AccountCombobox from '../AccountCombobox';
import Error from '../Error';
import withAccounts from '../withAccounts';
import AppContext from '../../AppContext';
import accountProp from '../../propTypes/account';
import categoryProp from '../../propTypes/category';
import { getActiveAccountCategories } from '../../selectors/categories';
import { formatMoney } from '../../utils/format';

class TransferForm extends React.PureComponent {
  handleFromAccountChange = (account) => {
    const {
      transaction: { splits },
      onChange
    } = this.props;
    const toSplits = splits.filter((split) => split.type === 'to');

    onChange({
      from_account_id: account.id,
      splits: [...toSplits, { type: 'from' }]
    });
  };

  handleToAccountChange = (account) => {
    const {
      transaction: { splits },
      onChange
    } = this.props;
    const fromSplits = splits.filter((split) => split.type === 'from');

    onChange({
      to_account_id: account.id,
      splits: [...fromSplits, { type: 'to' }]
    });
  };

  handleFromSplitChange = (split, index) => {
    const {
      transaction: { splits },
      onChange
    } = this.props;
    const fromSplits = splits.filter((split) => split.type === 'from');
    const toSplits = splits.filter((split) => split.type === 'to');

    onChange({
      splits: [...fromSplits.slice(0, index), split, ...fromSplits.slice(index + 1), ...toSplits]
    });
  };

  handleToSplitChange = (split, index) => {
    const {
      transaction: { splits },
      onChange
    } = this.props;
    const fromSplits = splits.filter((split) => split.type === 'from');
    const toSplits = splits.filter((split) => split.type === 'to');

    onChange({
      splits: [...toSplits.slice(0, index), split, ...toSplits.slice(index + 1), ...fromSplits]
    });
  };

  handleAddFromSplit = () => {
    const {
      transaction: { splits },
      onChange
    } = this.props;

    onChange({ splits: [...splits, { type: 'from' }] });
  };

  handleAddToSplit = () => {
    const {
      transaction: { splits },
      onChange
    } = this.props;

    onChange({ splits: [...splits, { type: 'to' }] });
  };

  handleRemoveFromSplit = (index) => {
    const {
      transaction: { splits },
      onChange
    } = this.props;
    const fromSplits = splits.filter((split) => split.type === 'from');
    const toSplits = splits.filter((split) => split.type === 'to');

    onChange({
      splits: [...fromSplits.slice(0, index), ...fromSplits.slice(index + 1), ...toSplits]
    });
  };

  handleRemoveToSplit = (index) => {
    const {
      transaction: { splits },
      onChange
    } = this.props;
    const fromSplits = splits.filter((split) => split.type === 'from');
    const toSplits = splits.filter((split) => split.type === 'to');

    onChange({
      splits: [...toSplits.slice(0, index), ...toSplits.slice(index + 1), ...fromSplits]
    });
  };

  renderFromSplitForm(split, errors, index) {
    const {
      accounts,
      categories,
      transaction: { from_account_id: fromAccountId, splits }
    } = this.props;
    const fromAccount = accounts.find((account) => account.id === fromAccountId);
    const fromSplits = splits.filter((split) => split.type === 'from');
    const usedCategoryIds = fromSplits.map((split) => split.category_id);
    const unusedCategories = getActiveAccountCategories(fromAccount, categories).filter(
      (category) => category.id === split.category_id || !usedCategoryIds.includes(category.id)
    );

    return (
      <SplitForm
        key={`${split.type}-category-${index}`}
        categories={unusedCategories}
        split={split}
        onChange={(split) => this.handleFromSplitChange(split, index)}
        onRemove={() => this.handleRemoveFromSplit(index)}
        single={fromSplits.length === 1}
        errors={errors}
      />
    );
  }

  renderToSplitForm(split, errors, index) {
    const {
      accounts,
      categories,
      transaction: { to_account_id: toAccountId, splits }
    } = this.props;
    const toAccount = accounts.find((account) => account.id === toAccountId);
    const toSplits = splits.filter((split) => split.type === 'to');
    const usedCategoryIds = toSplits.map((split) => split.category_id);
    const unusedCategories = getActiveAccountCategories(toAccount, categories).filter(
      (category) => category.id === split.category_id || !usedCategoryIds.includes(category.id)
    );

    return (
      <SplitForm
        key={`${split.type}-category-${index}`}
        categories={unusedCategories}
        split={split}
        onChange={(split) => this.handleToSplitChange(split, index)}
        onRemove={() => this.handleRemoveToSplit(index)}
        single={toSplits.length === 1}
        errors={errors}
      />
    );
  }

  renderFromAmountRemaining() {
    const {
      transaction: { amount, splits }
    } = this.props;
    const fromSplits = splits.filter((split) => split.type === 'from');

    return this.renderAmountRemaining(amount, fromSplits);
  }

  renderToAmountRemaining() {
    const {
      transaction: { amount, splits }
    } = this.props;
    const toSplits = splits.filter((split) => split.type === 'to');

    return this.renderAmountRemaining(amount, toSplits);
  }

  renderAmountRemaining(amount, splits) {
    if (splits.length < 2) {
      return null;
    }

    let amountRemaining = parseFloat(amount) || 0;

    amountRemaining = splits.reduce((acc, split) => {
      const splitAmount = parseFloat(split.amount);

      if (!splitAmount) {
        return acc;
      }

      return acc - splitAmount;
    }, amountRemaining);

    return (
      <p>
        {amountRemaining < 0 ? (
          <span className="negative">{formatMoney(amountRemaining / 100)}</span>
        ) : (
          formatMoney(amountRemaining / 100)
        )}{' '}
        remaining
      </p>
    );
  }

  render() {
    const {
      accounts,
      transaction: { from_account_id: fromAccountId, to_account_id: toAccountId, splits },
      errors
    } = this.props;

    const fromAccount = accounts.find((account) => account.id === fromAccountId);
    const toAccount = accounts.find((account) => account.id === toAccountId);

    const splitErrors = errors.splits || [];
    const splitsWithErrors = splits.map((split, index) => {
      const errors = splitErrors.length > index ? splitErrors[index] : {};
      return [split, errors];
    });

    return (
      <React.Fragment>
        <div className="w-full mb-6">
          <label htmlFor="transfer_from_account" className="label">
            From
          </label>

          <AccountCombobox
            account={fromAccount}
            onChange={this.handleFromAccountChange}
            errors={errors.from_account_id}
          />

          <Error errors={errors.from_account_id} prefix="From" />
        </div>

        {fromAccount
          ? splitsWithErrors
              .filter(([split]) => split.type === 'from')
              .map(([split, splitErrors], index) => this.renderFromSplitForm(split, splitErrors, index))
          : null}

        {fromAccount ? (
          <div className="grid grid-cols-2 gap-1 sm:gap-6 mb-6">
            <div className="col-span-2 sm:col-span-1">
              <button type="button" onClick={this.handleAddFromSplit} className="button w-full sm:w-auto">
                Add Split
              </button>
            </div>

            <div className="col-span-2 sm:col-span-1 text-center sm:text-right text-gray-500 sm:pt-6">
              {this.renderFromAmountRemaining()}
            </div>
          </div>
        ) : null}

        <div className="w-full mb-6">
          <label htmlFor="transfer_to_account" className="label">
            To
          </label>

          <AccountCombobox
            id="transfer_to_account"
            account={toAccount}
            onChange={this.handleToAccountChange}
            errors={errors.to_account_id}
          />

          <Error errors={errors.to_account_id} prefix="To" />
        </div>

        {toAccount
          ? splitsWithErrors
              .filter(([split]) => split.type === 'to')
              .map(([split, splitErrors], index) => this.renderToSplitForm(split, splitErrors, index))
          : null}

        {toAccount ? (
          <div className="grid grid-cols-2 gap-1 sm:gap-6 mb-6">
            <div className="col-span-2 sm:col-span-1">
              <button type="button" onClick={this.handleAddToSplit} className="button w-full sm:w-auto">
                Add Split
              </button>
            </div>

            <div className="col-span-2 sm:col-span-1 text-center sm:text-right text-gray-500 sm:pt-6">
              {this.renderToAmountRemaining()}
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

TransferForm.propTypes = {
  accounts: PropTypes.arrayOf(accountProp).isRequired,
  categories: PropTypes.arrayOf(categoryProp).isRequired,
  transaction: PropTypes.shape({
    type: PropTypes.oneOf(['payment', 'deposit', 'transfer']),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    payee: PropTypes.string,
    memo: PropTypes.string,
    from_account_id: PropTypes.number,
    to_account_id: PropTypes.number,
    splits: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(['from', 'to']).isRequired,
        category_id: PropTypes.number,
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    )
  }).isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default withAccounts((props) => {
  const { categories } = useContext(AppContext);
  return <TransferForm categories={categories} {...props} />;
});
