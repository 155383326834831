export const getActiveAccounts = (accounts) =>
  accounts
    .filter((account) => account.active)
    .sort((a, b) => {
      if (a.bank === b.bank) {
        return a.name.localeCompare(b.name);
      }

      return a.bank.localeCompare(b.bank);
    });
