import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import AppContext from '../../AppContext';
import AccountHeader from '../../components/AccountHeader';
import AccountForm from '../../components/AccountForm';
import CategoryList from '../../components/CategoryList';
import Navbar from '../../components/Navbar';
import { getLastAccount } from '../../localStorage';
import { createAccount } from '../../requests/accounts';
import { getActiveAccounts } from '../../selectors/accounts';

const NewAccount = () => {
  const [cancelled, setCancelled] = useState(false);
  const [created, setCreated] = useState();
  const [errors, setErrors] = useState({});
  const { accounts, refreshContext } = useContext(AppContext);
  const activeAccounts = getActiveAccounts(accounts);
  const lastAccount = getLastAccount(activeAccounts);

  const handleCancel = () => {
    setCancelled(true);
    setErrors({});
  };

  const handleSubmit = async (data) => {
    try {
      const account = await createAccount(data);
      await refreshContext();
      setCreated(account);
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  if (created) {
    return <Redirect to={`/accounts/${created.id}`} />;
  }

  if (cancelled) {
    if (lastAccount) {
      return <Redirect to={`/accounts/${lastAccount.id}`} />;
    }

    return <Redirect to="/" />;
  }

  return (
    <>
      <Navbar account={lastAccount} />

      {lastAccount && <AccountHeader account={lastAccount}>{(account) => `/accounts/${account.id}`}</AccountHeader>}

      <hr />

      <div className="container">
        {lastAccount ? (
          <div className="layout">
            <div>
              <CategoryList key={lastAccount.id} account={lastAccount}>
                {(category) => `/accounts/${lastAccount.id}/categories/${category.id}`}
              </CategoryList>
            </div>

            <div>
              <AccountForm
                account={{ name: '', bank: '' }}
                errors={errors}
                onSubmit={handleSubmit}
                onCancel={handleCancel}
              />
            </div>
          </div>
        ) : (
          <div className="pt-4">
            <AccountForm
              account={{ name: '', bank: '' }}
              errors={errors}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default NewAccount;
