import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Error from '../Error';

const LoginForm = ({ onSubmit, invalidLogin }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(email, password);
  };

  return (
    <div>
      <h2 className="text-2xl text-gray-700 pb-2">Login</h2>

      <form onSubmit={handleSubmit}>
        <div className="w-full mb-6">
          <label htmlFor="email" className="label">
            Email
          </label>

          <input
            id="email"
            type="text"
            className="input"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
        </div>

        <div className="w-full mb-6">
          <label htmlFor="password" className="label">
            Password
          </label>

          <input
            id="password"
            type="password"
            className="input"
            value={password}
            onChange={(event) => setPassword(event.target.value)}
          />
        </div>

        {invalidLogin && (
          <div className="mb-6">
            <Error errors={['are invalid']} prefix="Credentials" />
          </div>
        )}

        <div className="flex mb-9">
          <div className="flex-none">
            <button type="submit" className="primary-button">
              Login
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

LoginForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  invalidLogin: PropTypes.bool
};

export default LoginForm;
