import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import BudgetDialog from './BudgetDialog';
import SplitForm from './SplitForm';
import AccountCombobox from '../AccountCombobox';
import Error from '../Error';
import PayeeInput from '../PayeeInput';
import withAccounts from '../withAccounts';
import AppContext from '../../AppContext';
import accountProp from '../../propTypes/account';
import categoryProp from '../../propTypes/category';
import { getActiveAccountCategories } from '../../selectors/categories';
import { formatMoney } from '../../utils/format';

class DepositForm extends React.PureComponent {
  constructor() {
    super();
    this.state = { showBudgets: false };
  }

  handleAccountChange = (account) => {
    const {
      transaction: { splits },
      onChange
    } = this.props;
    const fromSplits = splits.filter((split) => split.type === 'from');

    onChange({
      to_account_id: account.id,
      splits: [...fromSplits, { type: 'to' }]
    });
  };

  handleSplitChange = (split, index) => {
    const {
      transaction: { splits },
      onChange
    } = this.props;
    const fromSplits = splits.filter((split) => split.type === 'from');
    const toSplits = splits.filter((split) => split.type === 'to');

    onChange({
      splits: [...toSplits.slice(0, index), split, ...toSplits.slice(index + 1), ...fromSplits]
    });
  };

  handleAddSplit = () => {
    const {
      transaction: { splits },
      onChange
    } = this.props;

    onChange({ splits: [...splits, { type: 'to' }] });
  };

  handleRemoveSplit = (index) => {
    const {
      transaction: { splits },
      onChange
    } = this.props;
    const fromSplits = splits.filter((split) => split.type === 'from');
    const toSplits = splits.filter((split) => split.type === 'to');

    onChange({
      splits: [...toSplits.slice(0, index), ...toSplits.slice(index + 1), ...fromSplits]
    });
  };

  handleShowBudgets = () => this.setState({ showBudgets: true });

  handleApplyBudget = (budget) => {
    const { onChange } = this.props;

    onChange({
      budget_id: budget.id,
      date: budget.start_date,
      amount: budget.amount,
      to_account_id: budget.account_id,
      splits: budget.budget_categories.map(({ amount, category_id: categoryId }) => ({
        type: 'to',
        amount,
        category_id: categoryId
      }))
    });

    this.setState({ showBudgets: false });
  };

  renderSplitForm(split, errors, index) {
    const {
      accounts,
      categories,
      transaction: { to_account_id: toAccountId, splits }
    } = this.props;
    const toAccount = accounts.find((account) => account.id === toAccountId);
    const toSplits = splits.filter((split) => split.type === 'to');
    const usedCategoryIds = toSplits.map((split) => split.category_id);
    const unusedCategories = getActiveAccountCategories(toAccount, categories).filter(
      (category) => category.id === split.category_id || !usedCategoryIds.includes(category.id)
    );

    return (
      <SplitForm
        key={`${split.type}-category-${index}`}
        categories={unusedCategories}
        split={split}
        onChange={(split) => this.handleSplitChange(split, index)}
        onRemove={() => this.handleRemoveSplit(index)}
        single={toSplits.length === 1}
        errors={errors}
      />
    );
  }

  renderAmountRemaining() {
    const {
      transaction: { amount, splits }
    } = this.props;
    const toSplits = splits.filter((split) => split.type === 'to');

    if (toSplits.length < 2) {
      return null;
    }

    let amountRemaining = parseFloat(amount) || 0;

    amountRemaining = toSplits.reduce((acc, split) => {
      const splitAmount = parseFloat(split.amount);

      if (!splitAmount) {
        return acc;
      }

      return acc - splitAmount;
    }, amountRemaining);

    return (
      <p>
        {amountRemaining < 0 ? (
          <span className="negative">{formatMoney(amountRemaining / 100)}</span>
        ) : (
          formatMoney(amountRemaining / 100)
        )}{' '}
        remaining
      </p>
    );
  }

  render() {
    const { showBudgets } = this.state;
    const { account, accounts, transaction, errors, onChange } = this.props;
    const { to_account_id: toAccountId, splits, payee } = transaction;
    const toAccount = accounts.find((account) => account.id === toAccountId);

    const splitErrors = errors.splits || [];
    const splitsWithErrors = splits.map((split, index) => {
      const errors = splitErrors.length > index ? splitErrors[index] : {};
      return [split, errors];
    });

    return (
      <React.Fragment>
        {showBudgets && (
          <BudgetDialog
            account={account}
            onClose={() => this.setState({ showBudgets: false })}
            onChange={this.handleApplyBudget}
          />
        )}

        <div className="w-full mb-6">
          <label htmlFor="deposit_payee" className="label">
            From
          </label>

          <PayeeInput
            id="deposit_payee"
            payee={payee}
            onChange={(payee) => onChange({ payee })}
            errors={errors.payee}
          />

          <Error errors={errors.payee} prefix="From" />
        </div>

        <div className="w-full mb-6">
          <label htmlFor="deposit_to_account" className="label">
            To
          </label>

          <div className="w-full flex">
            <div className="flex-grow">
              <AccountCombobox
                id="deposit_to_account"
                account={toAccount}
                onChange={this.handleAccountChange}
                errors={errors.to_account_id}
              />
            </div>

            {!transaction.id && (
              <div className="flex-none">
                <button type="button" onClick={this.handleShowBudgets} className="button mt-1 ml-1">
                  Use Budget
                </button>
              </div>
            )}
          </div>

          <Error errors={errors.to_account_id} prefix="To" />
        </div>

        {toAccount
          ? splitsWithErrors
              .filter(([split]) => split.type === 'to')
              .map(([split, splitErrors], index) => this.renderSplitForm(split, splitErrors, index))
          : null}

        {toAccount ? (
          <div className="grid grid-cols-2 gap-1 sm:gap-6 mb-6">
            <div className="col-span-2 sm:col-span-1">
              <button type="button" onClick={this.handleAddSplit} className="button w-full sm:w-auto">
                Add Split
              </button>
            </div>

            <div className="col-span-2 sm:col-span-1 text-center sm:text-right text-gray-500 sm:pt-6">
              {this.renderAmountRemaining()}
            </div>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

DepositForm.propTypes = {
  account: accountProp.isRequired,
  accounts: PropTypes.arrayOf(accountProp).isRequired,
  categories: PropTypes.arrayOf(categoryProp).isRequired,
  transaction: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.oneOf(['payment', 'deposit', 'transfer']),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    payee: PropTypes.string,
    memo: PropTypes.string,
    from_account_id: PropTypes.number,
    to_account_id: PropTypes.number,
    splits: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(['from', 'to']).isRequired,
        category_id: PropTypes.number,
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    )
  }).isRequired,
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
};

export default withAccounts((props) => {
  const { categories } = useContext(AppContext);
  return <DepositForm categories={categories} {...props} />;
});
