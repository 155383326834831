import React from 'react';
import PropTypes from 'prop-types';
import BudgetCategoryForm from './BudgetCategoryForm';
import AmountInput from '../AmountInput';
import DateInput from '../DateInput';
import Error from '../Error';
import withCategories from '../withCategories';
import accountProp from '../../propTypes/account';
import categoryProp from '../../propTypes/category';
import { formatMoney } from '../../utils/format';

class BudgetForm extends React.PureComponent {
  handleBudgetCategoryChange = (budgetCategory, index) => {
    const {
      budget: { budget_categories: budgetCategories },
      onChange
    } = this.props;

    onChange({
      budget_categories: [...budgetCategories.slice(0, index), budgetCategory, ...budgetCategories.slice(index + 1)]
    });
  };

  handleAddBudgetCategory = () => {
    const {
      budget: { budget_categories: budgetCategories },
      onChange
    } = this.props;

    onChange({ budget_categories: [...budgetCategories, { payee: '', budget_earmarks: [] }] });
  };

  handleRemoveBudgetCategory = (index) => {
    const {
      budget: { budget_categories: budgetCategories },
      onChange
    } = this.props;

    onChange({
      budget_categories: [...budgetCategories.slice(0, index), ...budgetCategories.slice(index + 1)]
    });
  };

  handleSubmit = (event) => {
    const { account, budget, onSubmit } = this.props;
    const { start_date: startDate, end_date: endDate, amount, budget_categories: budgetCategories } = budget;

    event.preventDefault();

    onSubmit({
      account_id: account.id,
      start_date: startDate,
      end_date: endDate,
      amount,
      budget_categories: budgetCategories.map((budgetCategory) => {
        const { category_id, amount, budget_earmarks: budgetEarmarks } = budgetCategory;

        return {
          amount,
          category_id,
          budget_earmarks: (budgetEarmarks || []).map((budgetEarmark) => {
            const { payee, amount } = budgetEarmark;
            return { payee, amount };
          })
        };
      })
    });
  };

  renderBudgetCategoryForm(budgetCategory, index) {
    const {
      errors,
      categories,
      budget: { budget_categories: budgetCategories }
    } = this.props;

    const category = categories.find((category) => category.id === budgetCategory.category_id);
    const usedCategoryIds = budgetCategories.map((budgetCategory) => budgetCategory.category_id);
    const unusedCategories = categories.filter(
      (category) => category.id === budgetCategory.category_id || !usedCategoryIds.includes(category.id)
    );

    return (
      <BudgetCategoryForm
        key={`budget-category-${index}`}
        category={category}
        categories={unusedCategories}
        budgetCategory={budgetCategory}
        onChange={(budgetCategory) => this.handleBudgetCategoryChange(budgetCategory, index)}
        onRemove={() => this.handleRemoveBudgetCategory(index)}
        single={budgetCategories.length === 1}
        errors={(errors.budget_categories && errors.budget_categories[index]) || {}}
      />
    );
  }

  renderAmountRemaining() {
    const {
      budget: { amount, budget_categories: budgetCategories }
    } = this.props;

    let amountRemaining = parseFloat(amount);

    if (!amountRemaining) {
      return null;
    }

    amountRemaining = budgetCategories.reduce((acc, budgetCategory) => {
      const budgetCategoryAmount = parseFloat(budgetCategory.amount);

      if (!budgetCategoryAmount) {
        return acc;
      }

      return acc - budgetCategoryAmount;
    }, amountRemaining);

    return (
      <p>
        {amountRemaining < 0 ? (
          <span className="text-red-500">{formatMoney(amountRemaining / 100)}</span>
        ) : (
          formatMoney(amountRemaining / 100)
        )}{' '}
        remaining
      </p>
    );
  }

  render() {
    const { budget, errors, onChange, onCancel, onCopy, onDelete } = this.props;
    const { start_date: startDate, end_date: endDate, amount } = budget;

    return (
      <div>
        <h2 className="text-2xl text-gray-700 pb-2">{budget.id ? 'Edit' : 'New'} Budget</h2>

        <form onSubmit={this.handleSubmit}>
          <div className="w-full sm:w-1/4 mb-6">
            <label htmlFor="budget_start_date" className="label">
              Start Date
            </label>

            <DateInput
              id="budget_start_date"
              date={startDate}
              onChange={(value) => onChange({ start_date: value })}
              errors={errors.start_date}
            />

            <Error errors={errors.start_date} prefix="Start date" />
          </div>

          <div className="w-full sm:w-1/4 mb-6">
            <label htmlFor="budget_end_date" className="label">
              End Date
            </label>

            <DateInput
              id="budget_end_date"
              date={endDate}
              onChange={(value) => onChange({ end_date: value })}
              errors={errors.end_date}
            />

            <Error errors={errors.end_date} prefix="End date" />
          </div>

          <div className="w-full sm:w-1/4 mb-6">
            <label htmlFor="budget_amount" className="label">
              Amount
            </label>

            <AmountInput
              id="budget_amount"
              amount={amount}
              onChange={(value) => onChange({ amount: value })}
              errors={errors.amount}
            />

            <Error errors={errors.amount} prefix="Amount" />
          </div>

          <hr />

          <h3 className="text-xl text-gray-700 pb-2 mt-4">Budget Categories</h3>

          {budget.budget_categories.map((budgetCategory, index) =>
            this.renderBudgetCategoryForm(budgetCategory, index)
          )}

          <div className="grid grid-cols-2 gap-1 sm:gap-6 mb-6">
            <div className="col-span-2 sm:col-span-1">
              <button type="button" onClick={this.handleAddBudgetCategory} className="button w-full sm:w-auto">
                Add Category
              </button>
            </div>

            <div className="col-span-2 sm:col-span-1 text-center sm:text-right text-gray-500 sm:pt-6">
              {this.renderAmountRemaining()}
            </div>
          </div>

          <div className="sm:flex mb-9 hidden">
            <div className="flex-none">
              <button type="submit" className="primary-button">
                Save
              </button>

              <button type="button" onClick={onCancel} className="button">
                Cancel
              </button>

              {onCopy && (
                <button type="button" onClick={onCopy} className="button">
                  Copy
                </button>
              )}
            </div>

            {onDelete && (
              <div className="flex-grow text-right">
                <button type="button" onClick={onDelete} className="button">
                  Delete
                </button>
              </div>
            )}
          </div>

          <div className="sm:hidden">
            <button type="submit" className="primary-button w-full mb-4">
              Save
            </button>

            <button type="button" onClick={onCancel} className="button w-full mb-4">
              Cancel
            </button>

            {onCopy && (
              <button type="button" onClick={onCopy} className="button w-full mb-4">
                Copy
              </button>
            )}

            {onDelete && (
              <div className="flex-grow text-right">
                <button type="button" onClick={onDelete} className="button w-full mb-4">
                  Delete
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

BudgetForm.propTypes = {
  account: accountProp.isRequired,
  categories: PropTypes.arrayOf(categoryProp).isRequired,
  budget: PropTypes.shape({
    id: PropTypes.number,
    start_date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    end_date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    budget_categories: PropTypes.arrayOf(
      PropTypes.shape({
        category_id: PropTypes.number,
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        budget_earmarks: PropTypes.arrayOf(
          PropTypes.shape({
            payee: PropTypes.string,
            amount: PropTypes.number
          })
        )
      })
    )
  }),
  errors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func
};

export default withCategories(BudgetForm);
