import React from 'react';
import Currency from '../Currency';
import accountProp from '../../propTypes/account';
import transactionProp from '../../propTypes/transaction';

const TransactionAmount = ({ account, transaction }) => {
  if (transaction.from_account_id === account.id) {
    return <Currency amount={transaction.amount * -1} />;
  }

  return <Currency amount={transaction.amount} />;
};

TransactionAmount.propTypes = {
  account: accountProp.isRequired,
  transaction: transactionProp.isRequired
};

export default TransactionAmount;
