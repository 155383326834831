import React from 'react';
import AccountHeader from '../../components/AccountHeader';
import CategoryList from '../../components/CategoryList';
import Navbar from '../../components/Navbar';
import SplitList from '../../components/SplitList';
import withAccount from '../../components/withAccount';
import withCategory from '../../components/withCategory';
import accountProp from '../../propTypes/account';
import categoryProp from '../../propTypes/category';

const CategoryTransactions = ({ account, category }) => (
  <>
    <Navbar account={account} />

    <AccountHeader account={account}>{(account) => `/accounts/${account.id}/transactions`}</AccountHeader>

    <hr />

    <div className="container">
      <div className="layout">
        <div>
          <CategoryList key={account.id} account={account} selectedCategory={category}>
            {(listCategory) =>
              `/accounts/${account.id}${
                category.id === listCategory.id ? '' : `/categories/${listCategory.id}`
              }/transactions`
            }
          </CategoryList>
        </div>

        <div>
          <SplitList key={category.id} account={account} category={category} />
        </div>
      </div>
    </div>
  </>
);

CategoryTransactions.propTypes = {
  account: accountProp.isRequired,
  category: categoryProp.isRequired
};

export default withAccount(withCategory(CategoryTransactions), 'accountId');
