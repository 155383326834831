import { useContext } from 'react';
import AppContext from '../../AppContext';
import accountProp from '../../propTypes/account';
import transactionProp from '../../propTypes/transaction';

const TransactionSplits = ({ account, transaction }) => {
  const { categories } = useContext(AppContext);
  let splits;

  if (transaction.from_account_id === account.id) {
    splits = transaction.splits.filter((split) => split.type === 'from');
  } else {
    splits = transaction.splits.filter((split) => split.type === 'to');
  }

  return splits
    .map((split) => {
      const category = categories.find((category) => category.id === split.category_id);
      return category.name;
    })
    .join(', ');
};

TransactionSplits.propTypes = {
  account: accountProp.isRequired,
  transaction: transactionProp.isRequired
};

export default TransactionSplits;
