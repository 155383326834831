import axios from 'axios';

export async function fetchAccounts() {
  const { data: accounts } = await axios.get('/api/accounts');

  return accounts;
}

export async function createAccount(params) {
  const { data: account } = await axios.post('/api/accounts', { account: params });

  return account;
}

export async function updateAccount(id, params) {
  const { data: account } = await axios.put(`/api/accounts/${id}`, { account: params });

  return account;
}

export async function deleteAccount(account) {
  await axios.delete(`/api/accounts/${account.id}`);
}
