import React from 'react';
import PropTypes from 'prop-types';
import CategoryMenuItem, { categoryToString } from './CategoryMenuItem';
import Combobox from '../Combobox';
import categoryProp from '../../propTypes/category';

const filterCategories = (inputValue, categories) => {
  if (!inputValue) {
    return categories;
  }

  const exactMatch = categories.find((category) => categoryToString(category) === inputValue);

  if (exactMatch) {
    return categories;
  }

  return categories.filter(
    (category) => !inputValue || categoryToString(category).toLowerCase().includes(inputValue.toLowerCase())
  );
};

const CategoryCombobox = ({ category, categories, errors, onChange }) => (
  <Combobox
    key={category && category.id}
    onChange={onChange}
    itemToString={categoryToString}
    initialSelection={category}
    errors={errors}
  >
    {({ highlightedIndex, getItemProps, inputValue }) =>
      filterCategories(inputValue, categories).map((category, index) => (
        <CategoryMenuItem
          key={category.id}
          category={category}
          index={index}
          highlightedIndex={highlightedIndex}
          getItemProps={getItemProps}
        />
      ))
    }
  </Combobox>
);

CategoryCombobox.propTypes = {
  category: categoryProp,
  categories: PropTypes.arrayOf(categoryProp).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired
};

export default CategoryCombobox;
