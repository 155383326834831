import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Currency from '../Currency';
import categoryProp from '../../propTypes/category';

const CategoryListItem = ({ category, selected, url }) => (
  <div className={classNames('list-item', { highlighted: selected })}>
    <Link to={url}>
      <div className="grid grid-cols-2 gap-x-4">
        <div className="truncate">{category.name}</div>

        <div className="text-right">
          <Currency amount={category.balance} />
        </div>
      </div>
    </Link>
  </div>
);

CategoryListItem.propTypes = {
  category: categoryProp.isRequired,
  selected: PropTypes.bool.isRequired,
  url: PropTypes.string.isRequired
};

export default CategoryListItem;
