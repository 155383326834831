import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CategoryListItem from './CategoryListItem';
import { ChevronDown, X } from '../Icons';
import withCategories from '../withCategories';
import accountProp from '../../propTypes/account';
import categoryProp from '../../propTypes/category';

const CategoryList = ({ account, categories, selectedCategory, children }) => {
  const [open, setOpen] = useState(false);
  const close = () => setOpen(false);
  const toggle = () => setOpen(!open);

  return (
    <>
      <div className="pb-4">
        <div className="flex">
          <div className="flex-grow-0">
            <h2 className="text-2xl text-gray-700 pb-2 hidden lg:block">Categories</h2>

            <h2 className="text-2xl text-gray-700 leading-10 lg:hidden">
              {selectedCategory ? selectedCategory.name : 'All Categories'}
            </h2>
          </div>

          <div className="flex-1 lg:hidden">
            <button
              className="inline-flex items-center justify-center p-2 ml-2 rounded-md text-gray-500 hover:text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              aria-expanded={open}
              onClick={toggle}
            >
              <span className="sr-only">Open category menu</span>

              <ChevronDown className={`${open ? 'hidden' : 'block'} h-6 w-6`} aria-hidden={open} />

              <X className={`${open ? 'block' : 'hidden'} h-6 w-6`} aria-hidden={!open} />
            </button>
          </div>
        </div>

        {categories.length === 0 && (
          <div className="mt-8">
            <Link to={`/accounts/${account.id}/categories/new`} className="button">
              New Category
            </Link>
          </div>
        )}

        <div className={`${open ? 'block' : 'hidden'} lg:block`}>
          <div className="py-1">
            {categories.map((category) => (
              <CategoryListItem
                key={category.id}
                category={category}
                selected={selectedCategory ? selectedCategory.id === category.id : false}
                url={children(category)}
                onClick={close}
              />
            ))}
          </div>
        </div>
      </div>

      <hr className="lg:hidden -ml-6 -mr-6" />
    </>
  );
};

CategoryList.propTypes = {
  account: accountProp.isRequired,
  categories: PropTypes.arrayOf(categoryProp),
  selectedCategory: categoryProp,
  children: PropTypes.func.isRequired
};

export default withCategories(CategoryList);
