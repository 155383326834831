import PropTypes from 'prop-types';

const transaction = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.oneOf(['payment', 'deposit', 'transfer']).isRequired,
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  amount: PropTypes.number.isRequired,
  payee: PropTypes.string,
  from_account_id: PropTypes.number,
  to_account_id: PropTypes.number,
  from_balance: PropTypes.number,
  to_balance: PropTypes.number,
  cleared: PropTypes.bool.isRequired,
  memo: PropTypes.string,
  splits: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      type: PropTypes.oneOf(['from', 'to']).isRequired,
      category_id: PropTypes.number.isRequired,
      amount: PropTypes.number.isRequired,
      balance: PropTypes.number.isRequired
    })
  )
});

export default transaction;
