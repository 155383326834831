import React from 'react';
import Currency from '../Currency';
import accountProp from '../../propTypes/account';
import transactionProp from '../../propTypes/transaction';

const TransactionBalance = ({ account, transaction }) => {
  if (transaction.from_account_id === account.id) {
    return <Currency amount={transaction.from_balance} />;
  }

  return <Currency amount={transaction.to_balance} />;
};

TransactionBalance.propTypes = {
  account: accountProp.isRequired,
  transaction: transactionProp.isRequired
};

export default TransactionBalance;
