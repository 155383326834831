import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '../Autocomplete';
import { fetchPayees } from '../../requests/transactions';

const PayeeInput = ({ id, payee, errors, onChange }) => {
  const [payees, setPayees] = useState([]);

  useEffect(async () => {
    if (payee && payee.length > 0) {
      const payees = await fetchPayees(payee);
      setPayees(payees);
    } else {
      setPayees([]);
    }
  }, [payee]);

  return (
    <Autocomplete id={id} value={payee} onChange={onChange} initialSelection={payee} items={payees} errors={errors} />
  );
};

PayeeInput.propTypes = {
  id: PropTypes.string,
  payee: PropTypes.string,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired
};

export default PayeeInput;
