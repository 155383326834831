/* eslint-disable react/display-name */

import React, { useContext } from 'react';
import AppContext from '../AppContext';
import { getActiveAccounts } from '../selectors/accounts';

const withAccounts = (WrappedComponent) => {
  return (props) => {
    const { accounts } = useContext(AppContext);
    const activeAccounts = getActiveAccounts(accounts);

    return <WrappedComponent accounts={activeAccounts} {...props} />;
  };
};

export const withAllAccounts = (WrappedComponent) => {
  return (props) => {
    const { accounts } = useContext(AppContext);

    return <WrappedComponent accounts={accounts} {...props} />;
  };
};

export default withAccounts;
