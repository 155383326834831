import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { parseDate, formatDate } from '../../utils/format';

const UP = 38;
const DOWN = 40;

const DateInput = ({ date, onChange, errors, ...rest }) => {
  const [value, setValue] = useState('');
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (!focused) {
      const parsedDate = parseDate(date);
      setValue(formatDate(parsedDate));
    }
  }, [focused, date]);

  const handleFocus = () => setFocused(true);

  const handleBlur = () => setFocused(false);

  const handleChange = (event) => {
    const newValue = event.target.value;
    const newDate = parseDate(newValue);

    setValue(newValue);

    if (newDate) {
      onChange(newDate);
    }
  };

  const increaseMonth = () => {
    const parsedDate = parseDate(value);

    if (parsedDate) {
      parsedDate.setMonth(parsedDate.getMonth() + 1);
      setValue(formatDate(parsedDate));
      onChange(parsedDate);
    }
  };

  const decreaseMonth = () => {
    const parsedDate = parseDate(value);

    if (parsedDate) {
      parsedDate.setMonth(parsedDate.getMonth() - 1);
      setValue(formatDate(parsedDate));
      onChange(parsedDate);
    }
  };

  const increaseDay = () => {
    const parsedDate = parseDate(value);

    if (parsedDate) {
      parsedDate.setDate(parsedDate.getDate() + 1);
      setValue(formatDate(parsedDate));
      onChange(parsedDate);
    }
  };

  const decreaseDay = () => {
    const parsedDate = parseDate(value);

    if (parsedDate) {
      parsedDate.setDate(parsedDate.getDate() - 1);
      setValue(formatDate(parsedDate));
      onChange(parsedDate);
    }
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === UP || event.keyCode === DOWN) {
      event.preventDefault();
    }

    if (event.shiftKey) {
      if (event.keyCode === UP) {
        increaseMonth();
      } else if (event.keyCode === DOWN) {
        decreaseMonth();
      }
    } else {
      if (event.keyCode === UP) {
        increaseDay();
      } else if (event.keyCode === DOWN) {
        decreaseDay();
      }
    }
  };

  return (
    <input
      {...rest}
      type="text"
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      className={classNames('input', { 'input-error': errors })}
    />
  );
};

DateInput.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired
};

export default DateInput;
