import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Plus } from '../Icons';
import Error from '../Error';
import accountProp from '../../propTypes/account';
import categoryProp from '../../propTypes/category';

const CategoryForm = ({ account, errors, onCancel, onDelete, onSubmit, ...rest }) => {
  const [category, setCategory] = useState(rest.category);

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    }

    setCategory(rest.category);
  };

  const handleChange = (changes) => setCategory({ ...category, ...changes });

  const handleSubmit = (event) => {
    event.preventDefault();
    const { name } = category;
    onSubmit({ account_id: account.id, name });
  };

  return (
    <div>
      <div className="flex">
        <div className="flex-1">
          <h2 className="text-2xl text-gray-700 pb-2">{category.id ? 'Edit' : 'New'} Category</h2>
        </div>

        <div className="flex-grow-0">
          <Link
            to={`/accounts/${account.id}/categories/new`}
            className="primary-button px-2 py-1 mr-2 flex items-center justify-center"
          >
            <Plus className="inline h-6 w-6" />
          </Link>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="w-full mb-6">
          <label htmlFor="category_name" className="label">
            Name
          </label>

          <input
            id="category_name"
            type="text"
            className={classNames('input', { 'input-error': errors.name })}
            value={category.name}
            onChange={(event) => handleChange({ name: event.target.value })}
          />

          <Error errors={errors.name} prefix="Name" />
        </div>

        <div className="flex mb-9">
          <div className="flex-none">
            <button type="submit" className="primary-button">
              Save
            </button>

            <button type="button" onClick={handleCancel} className="button">
              Cancel
            </button>
          </div>

          {onDelete && (
            <div className="flex-grow text-right">
              <button type="button" onClick={onDelete} className="button">
                Delete
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

CategoryForm.propTypes = {
  account: accountProp.isRequired,
  category: categoryProp.isRequired,
  errors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func
};

export default CategoryForm;
