import { useCallback, useEffect, useState } from 'react';

const popupStylesFromNode = (node) => {
  const rect = node.getBoundingClientRect();

  if (node.closest('[role="dialog"]')) {
    return {
      position: 'fixed',
      top: rect.bottom,
      left: rect.left,
      width: rect.width
    };
  }

  return {
    top: rect.bottom + window.scrollY,
    left: rect.left + window.scrollX,
    width: rect.width
  };
};

const usePopup = () => {
  const [popupContainer, setPopupContainer] = useState();
  const [popupStyles, setPopupStyles] = useState({ top: 0, left: 0, width: 0 });
  const [inputEl, setInputEl] = useState();

  useEffect(() => {
    const popupRoot = document.getElementById('popup-root');
    const container = document.createElement('div');
    popupRoot.appendChild(container);
    setPopupContainer(container);

    return function cleanup() {
      popupRoot.removeChild(container);
    };
  }, []);

  useEffect(() => {
    if (inputEl) {
      const observer = new ResizeObserver(() => {
        if (inputEl) {
          const styles = popupStylesFromNode(inputEl);
          setPopupStyles(styles);
        }
      });

      observer.observe(popupContainer);

      return function cleanup() {
        observer.disconnect();
      };
    }
  }, [popupContainer, inputEl]);

  const inputRef = useCallback((node) => {
    if (node) {
      const styles = popupStylesFromNode(node);
      setPopupStyles(styles);
      setInputEl(node);
    }
  }, []);

  return [inputRef, popupStyles, popupContainer];
};

export default usePopup;
