import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Currency from '../Currency';
import Modal, { ModalBody, ModalFooter } from '../Modal';
import accountProp from '../../propTypes/account';
import { fetchAccountBudgets } from '../../requests/budgets';
import { formatShortDate } from '../../utils/format';

const BudgetDialog = ({ account, onChange, onClose }) => {
  const [budgets, setBudgets] = useState([]);

  useEffect(async () => {
    const { items } = await fetchAccountBudgets(account, 1, { applied: false, per_page: 5 });
    setBudgets(items);
  }, []);

  return (
    <Modal>
      <ModalBody>
        <h3 className="text-xl text-gray-700 pb-2">Budgets</h3>

        {budgets.length === 0 && <p className="empty">No budgets.</p>}

        {budgets.map((budget) => (
          <div
            key={budget.id}
            className="flex px-2 py-1 rounded-md hover:bg-gray-100 hover:text-gray-700 text-gray-500 cursor-pointer"
            onClick={() => onChange(budget)}
          >
            <div className="flex-grow">{formatShortDate(budget.start_date)}</div>
            <div className="flex-grow">{formatShortDate(budget.end_date)}</div>
            <div className="flex-grow text-right">
              <Currency amount={budget.amount} />
            </div>
          </div>
        ))}
      </ModalBody>

      <ModalFooter>
        <div className="text-right">
          <button type="button" className="button mr-0" onClick={onClose}>
            Done
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

BudgetDialog.propTypes = {
  account: accountProp.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

export default BudgetDialog;
