import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ChevronDown, X } from '../Icons';

const TYPES = ['payment', 'deposit', 'transfer'];

const typeToString = (type) => {
  if (type === 'deposit') {
    return 'Deposit';
  }

  if (type === 'transfer') {
    return 'Transfer';
  }

  return 'Payment';
};

const TypeInput = ({ type, onChange }) => {
  const [open, setOpen] = useState(false);

  const handleChange = (type) => {
    setOpen(false);
    onChange(type);
  };

  return (
    <div className="relative">
      <button type="button" className="input" onClick={() => setOpen(!open)}>
        {typeToString(type)}

        <span className="w-6 h-6 absolute top-0 right-0 mt-2.5 mr-2 text-gray-500 focus:outline-none">
          <ChevronDown className={`${open ? 'hidden' : 'block'} w-6 h-6`} />

          <X className={`${open ? 'block' : 'hidden'} w-6 h-6`} />
        </span>
      </button>

      <div
        className={`${
          open ? 'block' : 'hidden'
        } absolute z-10 mt-1 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5`}
      >
        <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          {TYPES.map((type) => (
            <div key={type} className="menu-item" onClick={() => handleChange(type)}>
              {typeToString(type)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

TypeInput.propTypes = {
  type: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default TypeInput;
