import React from 'react';
import PropTypes from 'prop-types';

export const ModalBody = ({ children }) => <div className="bg-white px-3 pt-5 pb-4">{children}</div>;

ModalBody.propTypes = {
  children: PropTypes.any
};

export const ModalFooter = ({ children }) => <div className="px-3 py-3">{children}</div>;

ModalFooter.propTypes = {
  children: PropTypes.any
};

const Modal = ({ children }) => (
  <div className="fixed z-10 inset-0 overflow-y-auto" role="dialog">
    <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      {/* Background overlay */}
      <div className="fixed inset-0 transition-opacity" aria-hidden="true">
        <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
      </div>

      {/* This element is to trick the browser into centering the modal contents. */}
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>

      {/* Modal panel */}
      <div
        className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        {children}
      </div>
    </div>
  </div>
);

Modal.propTypes = {
  children: PropTypes.any
};

export default Modal;
