/* eslint-disable no-prototype-builtins */
import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import classNames from 'classnames';
import usePopup from '../usePopup';

const Autocomplete = ({ id, value, onChange, items, errors }) => {
  const [inputRef, popupStyles, popupContainer] = usePopup();

  const handleStateChange = (changes) => {
    if (changes.hasOwnProperty('selectedItem')) {
      onChange(changes.selectedItem);
    } else if (changes.hasOwnProperty('inputValue')) {
      onChange(changes.inputValue);
    }
  };

  return (
    <Downshift selectedItem={value} onStateChange={handleStateChange}>
      {(downshiftProps) => {
        const { getInputProps, getItemProps, getMenuProps, highlightedIndex, isOpen } = downshiftProps;

        return (
          <div className="relative">
            <input
              ref={inputRef}
              id={id}
              type="text"
              className={classNames('input', { 'input-error': errors })}
              {...getInputProps()}
            />

            {isOpen &&
              items.length > 0 &&
              ReactDOM.createPortal(
                <div className="popup" style={popupStyles}>
                  <div className="py-1" {...getMenuProps()}>
                    {items.map((item, index) => (
                      <div
                        key={item}
                        className={classNames('menu-item', { highlighted: index === highlightedIndex })}
                        {...getItemProps({ key: item, item, index })}
                      >
                        {item}
                      </div>
                    ))}
                  </div>
                </div>,
                popupContainer
              )}
          </div>
        );
      }}
    </Downshift>
  );
};

Autocomplete.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  initialSelection: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
  errors: PropTypes.arrayOf(PropTypes.string)
};

export default Autocomplete;
