import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import EarmarkDialog from './EarmarkDialog';
import SplitListItem from './SplitListItem';
import { Bookmark, Plus } from '../Icons';
import accountProp from '../../propTypes/account';
import categoryProp from '../../propTypes/category';
import { createEarmark, updateEarmark, deleteEarmark, fetchCategoryEarmarks } from '../../requests/earmarks';
import { fetchClearedCategorySplits, fetchPendingCategorySplits } from '../../requests/splits';
import { formatShortDate } from '../../utils/format';

const SplitList = ({
  account,
  category,
  fetchCategoryEarmarks,
  fetchClearedCategorySplits,
  fetchPendingCategorySplits
}) => {
  const [pendingSplits, setPendingSplits] = useState([]);
  const [clearedSplits, setClearedSplits] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const [earmarks, setEarmarks] = useState([]);
  const [showEarmarks, setShowEarmarks] = useState(false);

  useEffect(async () => {
    const items = await fetchPendingCategorySplits(category);
    setPendingSplits(items);
  }, [category.id]);

  useEffect(async () => {
    const { items, total_count: totalCount } = await fetchClearedCategorySplits(category, page);
    setHasMore(totalCount > clearedSplits.length + items.length);
    setClearedSplits(clearedSplits.concat(items));
  }, [category.id, page]);

  useEffect(async () => {
    const earmarks = await fetchCategoryEarmarks(category);
    setEarmarks(earmarks);
  }, [category.id]);

  const nextPage = useCallback(() => setPage(page + 1));

  const handleRemoveEarmark = async (earmark) => {
    if (window.confirm('Are you sure you want to delete this earmark?')) {
      await deleteEarmark(earmark);
      const earmarks = await fetchCategoryEarmarks(category);
      setEarmarks(earmarks);
    }
  };

  const handleUpdateEarmark = async (earmark) => {
    const { amount, payee, category_id: categoryId } = earmark;
    const params = { amount, payee, category_id: categoryId };

    if (earmark.id) {
      await updateEarmark(earmark.id, params);
    } else {
      await createEarmark(params);
    }

    const earmarks = await fetchCategoryEarmarks(category);
    setEarmarks(earmarks);
  };

  const groupedPendingSplits = pendingSplits.reduce((acc, split) => {
    return {
      ...acc,
      [split.transaction.date]: [
        ...(acc[split.transaction.date] || []),
        <SplitListItem key={split.id} account={account} category={category} split={split} />
      ]
    };
  }, {});

  const groupedClearedSplits = clearedSplits.reduce((acc, split) => {
    return {
      ...acc,
      [split.transaction.date]: [
        ...(acc[split.transaction.date] || []),
        <SplitListItem key={split.id} account={account} category={category} split={split} />
      ]
    };
  }, {});

  return (
    <>
      <div className="flex">
        <div className="flex-grow-0">
          <h2 className="text-2xl text-gray-700 pb-2">
            {pendingSplits.length > 0 ? 'Pending Transactions' : 'Transactions'}
          </h2>
        </div>

        <div className="flex-1">
          {earmarks.length === 0 && (
            <button
              type="button"
              onClick={() => setShowEarmarks(true)}
              className="text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-0 mt-1 ml-2"
            >
              <Bookmark className="w-6 h-6" />
            </button>
          )}

          {earmarks.length > 0 && (
            <button
              type="button"
              onClick={() => setShowEarmarks(true)}
              className="rounded-full py-1 px-3 bg-gray-100 hover:bg-gray-200 ml-1"
            >
              {earmarks.length}
              <Bookmark className="inline w-6 h-6 pl-1" />
            </button>
          )}
        </div>

        <div className="flex-grow-0">
          <Link
            to={`/accounts/${account.id}/transactions/new`}
            className="button text-white bg-green-500 hover:bg-green-600 px-2 py-1 mr-2 flex items-center justify-center"
          >
            <Plus className="inline h-6 w-6" />
          </Link>
        </div>
      </div>

      {pendingSplits.length === 0 && clearedSplits.length === 0 && <p className="empty">No transactions.</p>}

      {Object.keys(groupedPendingSplits).reduce(
        (acc, date) => [
          ...acc,
          <div key={date} className="date-divider">
            {formatShortDate(date)}
          </div>,
          ...groupedPendingSplits[date]
        ],
        []
      )}

      {pendingSplits.length > 0 && clearedSplits.length > 0 && (
        <h2 className="mt-10 text-2xl text-gray-700 pb-2">Cleared Transactions</h2>
      )}

      {Object.keys(groupedClearedSplits).reduce(
        (acc, date) => [
          ...acc,
          <div key={date} className="date-divider">
            {formatShortDate(date)}
          </div>,
          ...groupedClearedSplits[date]
        ],
        []
      )}

      {hasMore && (
        <div className="mt-9 mb-9 text-center">
          <button type="button" className="button" onClick={nextPage}>
            Load More
          </button>
        </div>
      )}

      {showEarmarks && (
        <EarmarkDialog
          account={account}
          category={category}
          earmarks={earmarks}
          onClose={() => setShowEarmarks(false)}
          onUpdate={handleUpdateEarmark}
          onRemove={handleRemoveEarmark}
        />
      )}
    </>
  );
};

SplitList.propTypes = {
  account: accountProp.isRequired,
  category: categoryProp.isRequired,
  fetchCategoryEarmarks: PropTypes.func.isRequired,
  fetchClearedCategorySplits: PropTypes.func.isRequired,
  fetchPendingCategorySplits: PropTypes.func.isRequired
};

SplitList.defaultProps = {
  fetchCategoryEarmarks,
  fetchClearedCategorySplits,
  fetchPendingCategorySplits
};

export default SplitList;
