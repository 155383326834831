import PropTypes from 'prop-types';

const split = PropTypes.shape({
  id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  category_id: PropTypes.number.isRequired,
  amount: PropTypes.number.isRequired,
  balance: PropTypes.number.isRequired,
  transaction: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    amount: PropTypes.number.isRequired,
    payee: PropTypes.string,
    from_account_id: PropTypes.number,
    to_account_id: PropTypes.number,
    from_balance: PropTypes.number,
    to_balance: PropTypes.number,
    cleared: PropTypes.bool.isRequired,
    memo: PropTypes.string
  })
});

export default split;
