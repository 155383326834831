/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { useContext } from 'react';
import AppContext from '../AppContext';
import { getActiveAccountCategories } from '../selectors/categories';

const withCategory = (WrappedComponent) => {
  return (props) => {
    const { categories } = useContext(AppContext);
    const { account } = props;
    const activeCategories = getActiveAccountCategories(account, categories);

    return <WrappedComponent categories={activeCategories} {...props} />;
  };
};

export default withCategory;
