import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import accountProp from '../../propTypes/account';

export const accountToString = (account) => {
  if (account) {
    return `${account.name} - ${account.bank}`;
  }

  return '';
};

const AccountMenuItem = ({ account, index, highlightedIndex, getItemProps }) => (
  <div
    className={classNames('menu-item', { highlighted: index === highlightedIndex })}
    {...getItemProps({
      key: `account-${account.id}`,
      item: account,
      index
    })}
  >
    {accountToString(account)}
  </div>
);

AccountMenuItem.propTypes = {
  account: accountProp,
  index: PropTypes.number.isRequired,
  highlightedIndex: PropTypes.number,
  getItemProps: PropTypes.func.isRequired
};

export default AccountMenuItem;
