import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation } from 'react-router-dom';
import AccountHeader from '../../components/AccountHeader';
import BudgetForm from '../../components/BudgetForm';
import Navbar from '../../components/Navbar';
import withAccount from '../../components/withAccount';
import accountProp from '../../propTypes/account';
import { createBudget, fetchBudget } from '../../requests/budgets';
import { formatISODate } from '../../utils/format';

const NewBudget = ({ account, match: { params } }) => {
  const query = new URLSearchParams(useLocation().search);
  const budgetId = query.get('copy');

  const [budget, setBudget] = useState();
  const [cancelled, setCancelled] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(async () => {
    const today = new Date();
    const startDate = formatISODate(today);
    const endDate = formatISODate(today.setDate(today.getDate() + 14));

    if (budgetId) {
      const budget = await fetchBudget(budgetId);
      setBudget({ ...budget, id: null, start_date: startDate, end_date: endDate });
    } else {
      setBudget({
        start_date: startDate,
        end_date: endDate,
        amount: '',
        budget_categories: [{ payee: '', budget_earmarks: [] }]
      });
    }
  }, [params.id, budgetId]);

  const handleChange = (changes) => setBudget({ ...budget, ...changes });
  const handleCancel = () => setCancelled(true);

  const handleSubmit = async (data) => {
    try {
      await createBudget(data);
      setCancelled(true);
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  if (!budget) {
    return null;
  }

  if (cancelled) {
    return <Redirect to={`/accounts/${account.id}/budgets`} />;
  }

  return (
    <>
      <Navbar account={account} />

      <AccountHeader account={account}>{(account) => `/accounts/${account.id}/budgets`}</AccountHeader>

      <hr />

      <div className="container">
        <div className="pt-4">
          <BudgetForm
            key={account.id}
            account={account}
            budget={budget}
            errors={errors}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
          />
        </div>
      </div>
    </>
  );
};

NewBudget.propTypes = {
  account: accountProp.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
};

export default withAccount(NewBudget);
