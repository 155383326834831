import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import AppContext from '../../AppContext';
import AccountHeader from '../../components/AccountHeader';
import AccountForm from '../../components/AccountForm';
import CategoryList from '../../components/CategoryList';
import Navbar from '../../components/Navbar';
import { updateAccount, deleteAccount } from '../../requests/accounts';
import { getActiveAccounts } from '../../selectors/accounts';

const EditAccount = ({ match: { params } }) => {
  const [deleted, setDeleted] = useState(false);
  const [errors, setErrors] = useState({});
  const { accounts, refreshContext } = useContext(AppContext);
  const account = accounts.find((account) => account.id.toString() === params.id);

  const handleCancel = () => setErrors({});

  const handleSubmit = async (data) => {
    try {
      await updateAccount(account.id, data);
      await refreshContext();
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('Are you sure you want to delete this account?')) {
      await deleteAccount(account);
      await refreshContext();
      setDeleted(true);
    }
  };

  if (deleted) {
    const activeAccounts = getActiveAccounts(accounts);

    if (activeAccounts.length > 0) {
      return <Redirect to={`/accounts/${activeAccounts[0].id}`} />;
    }

    return <Redirect to="/" />;
  }

  return (
    <>
      <Navbar account={account} />

      <AccountHeader account={account}>{(account) => `/accounts/${account.id}`}</AccountHeader>

      <hr />

      <div className="container">
        <div className="layout">
          <div>
            <CategoryList key={account.id} account={account}>
              {(category) => `/accounts/${account.id}/categories/${category.id}`}
            </CategoryList>
          </div>

          <div>
            <AccountForm
              key={account.id}
              account={account}
              errors={errors}
              onCancel={handleCancel}
              onSubmit={handleSubmit}
              onDelete={handleDelete}
            />
          </div>
        </div>
      </div>
    </>
  );
};

EditAccount.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
};

export default EditAccount;
