import React from 'react';
import PropTypes from 'prop-types';
import AmountInput from '../AmountInput';
import Currency from '../Currency';
import Error from '../Error';
import { X } from '../Icons';
import Modal, { ModalBody, ModalFooter } from '../Modal';
import PayeeInput from '../PayeeInput';
import categoryProp from '../../propTypes/category';

const EarmarksForm = ({ category, earmarks, errors, onAdd, onUpdate, onRemove, onClose, onApply }) => {
  const total = earmarks.reduce((sum, { amount }) => sum + amount, 0);

  return (
    <Modal>
      <ModalBody>
        <h3 className="text-xl text-gray-700 pb-2">{category.name} Earmarks</h3>

        {earmarks.length === 0 && <p className="empty">No earmarks.</p>}

        {earmarks.map((earmark, index) => {
          const earmarkErrors = errors[index] || {};

          return (
            <div key={index} className="grid grid-cols-3 gap-4">
              <div className="col-span-2">
                <PayeeInput
                  payee={earmark.payee}
                  errors={earmarkErrors.payee}
                  onChange={(payee) => onUpdate(index, { payee })}
                />
              </div>

              <div className="flex">
                <AmountInput
                  amount={earmark.amount}
                  errors={earmarkErrors.amount}
                  onChange={(value) => onUpdate(index, { amount: value })}
                />

                <div className="flex-grow-0">
                  <button type="button" className="icon-button" onClick={() => onRemove(index)}>
                    <X className="w-6 h-6" />
                  </button>
                </div>
              </div>

              <div className="col-span-2 -mt-4">
                <Error errors={earmarkErrors.payee} prefix="Payee" />
              </div>

              <div className="-mt-4">
                <Error errors={earmarkErrors.amount} prefix="Amount" />
              </div>
            </div>
          );
        })}

        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-2 text-right">Total:</div>

          <Currency amount={total} />
        </div>
      </ModalBody>

      <ModalFooter>
        <div className="grid grid-cols-2">
          <div>
            <button type="button" className="button" onClick={onAdd}>
              Add Earmark
            </button>
          </div>

          <div className="text-right">
            <button type="button" className="button" onClick={onClose}>
              Done
            </button>

            <button type="button" className="button mr-0" onClick={onApply}>
              Apply
            </button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

EarmarksForm.propTypes = {
  category: categoryProp.isRequired,
  earmarks: PropTypes.arrayOf(
    PropTypes.shape({
      payee: PropTypes.string,
      amount: PropTypes.number
    })
  ),
  errors: PropTypes.object.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onApply: PropTypes.func.isRequired
};

export default EarmarksForm;
