import PropTypes from 'prop-types';

const account = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string.isRequired,
  bank: PropTypes.string.isRequired,
  balance: PropTypes.number
});

export default account;
