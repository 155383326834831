import React from 'react';
import PropTypes from 'prop-types';
import AmountInput from '../AmountInput';
import PayeeInput from '../PayeeInput';
import earmarkProp from '../../propTypes/earmark';

const EarmarkForm = ({ earmark, onUpdate }) => (
  <div className="grid grid-cols-3 gap-4">
    <div className="col-span-2">
      <label className="label">Payee</label>

      <PayeeInput payee={earmark.payee} onChange={(payee) => onUpdate({ payee })} />
    </div>

    <div className="col-span-1">
      <label className="label">Amount</label>

      <AmountInput amount={earmark.amount} onChange={(value) => onUpdate({ amount: value })} />
    </div>
  </div>
);

EarmarkForm.propTypes = {
  earmark: earmarkProp.isRequired,
  onUpdate: PropTypes.func.isRequired
};

export default EarmarkForm;
