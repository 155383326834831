/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../AppContext';
import { getActiveAccountCategories } from '../selectors/categories';

const withCategory = (WrappedComponent, key = 'id') => {
  return (props) => {
    const { categories } = useContext(AppContext);
    const [category, setCategory] = useState();
    const {
      account,
      match: { params }
    } = props;

    useEffect(() => {
      const activeCategories = getActiveAccountCategories(account, categories);
      const category = activeCategories.find((category) => category.id.toString() === params[key]);
      setCategory(category);
    }, [params[key]]);

    if (category) {
      return <WrappedComponent category={category} {...props} />;
    }

    return null;
  };
};

export default withCategory;
