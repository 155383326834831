import React from 'react';
import PropTypes from 'prop-types';
import DepositForm from './DepositForm';
import PaymentForm from './PaymentForm';
import TransferForm from './TransferForm';
import TypeInput from './TypeInput';
import AmountInput from '../AmountInput';
import DateInput from '../DateInput';
import Error from '../Error';
import accountProp from '../../propTypes/account';

class TransactionForm extends React.PureComponent {
  handleSubmit = (event) => {
    event.preventDefault();
    const { transaction, onSubmit } = this.props;

    const {
      type,
      date,
      amount,
      payee,
      memo,
      budget_id: budgetId,
      earmark_id: earmarkId,
      from_account_id: fromAccountId,
      to_account_id: toAccountId,
      splits
    } = transaction;
    let data = { type, date, amount, memo };

    let fromSplits = splits
      .filter((split) => split.type === 'from')
      .map((split) => {
        const { type, category_id: categoryId, amount } = split;
        return { type, amount, category_id: categoryId };
      });

    let toSplits = splits
      .filter((split) => split.type === 'to')
      .map((split) => {
        const { type, category_id: categoryId, amount } = split;
        return { type, amount, category_id: categoryId };
      });

    if (fromSplits.length === 1) {
      fromSplits = [{ ...fromSplits[0], amount }];
    }

    if (toSplits.length === 1) {
      toSplits = [{ ...toSplits[0], amount }];
    }

    if (type === 'payment') {
      data = {
        ...data,
        earmark_id: earmarkId,
        from_account_id: fromAccountId,
        payee: payee.trim(),
        splits: fromSplits
      };
    }

    if (type === 'deposit') {
      data = {
        ...data,
        budget_id: budgetId,
        to_account_id: toAccountId,
        payee: payee.trim(),
        splits: toSplits
      };
    }

    if (type === 'transfer') {
      data = {
        ...data,
        from_account_id: fromAccountId,
        to_account_id: toAccountId,
        splits: [...fromSplits, ...toSplits]
      };

      if (fromAccountId === toAccountId) {
        data.cleared = true;
      }
    }

    if (data.memo) {
      data.memo = data.memo.trim();
    }

    onSubmit(data);
  };

  render() {
    const { transaction, errors, onChange, onCancel, onDelete } = this.props;
    const { type, date, amount, memo } = transaction;

    return (
      <div className="TransactionForm">
        <h2 className="text-2xl text-gray-700 pb-2">{transaction.id ? 'Edit' : 'New'} Transaction</h2>

        <form onSubmit={this.handleSubmit}>
          <div className="w-full sm:w-1/4 mb-6">
            <label htmlFor="transaction_type" className="label">
              Type
            </label>

            <TypeInput type={type} onChange={(type) => onChange({ type })} />
          </div>

          <div className="w-full sm:w-1/4 mb-6">
            <label htmlFor="transaction_date" className="label">
              Date
            </label>

            <DateInput
              id="transaction_date"
              date={date}
              onChange={(value) => onChange({ date: value })}
              errors={errors.date}
            />

            <Error errors={errors.date} prefix="Date" />
          </div>

          <div className="w-full sm:w-1/4 mb-6">
            <label htmlFor="transaction_amount" className="label">
              Amount
            </label>

            <AmountInput
              id="transaction_amount"
              amount={amount}
              onChange={(value) => onChange({ amount: value })}
              errors={errors.amount}
            />

            <Error errors={errors.amount} prefix="Amount" />
          </div>

          {transaction.type === 'payment' && <PaymentForm {...this.props} />}

          {transaction.type === 'deposit' && <DepositForm {...this.props} />}

          {transaction.type === 'transfer' && <TransferForm {...this.props} />}

          <div className="w-full mb-6">
            <label htmlFor="payment_memo" className="label">
              Memo
            </label>

            <input
              id="payment_memo"
              type="text"
              className="input"
              value={memo || ''}
              onChange={(event) => onChange({ memo: event.target.value })}
            />
          </div>

          <div className="sm:flex mb-9 hidden">
            <div className="flex-none">
              <button type="submit" className="primary-button">
                Save
              </button>

              <button type="button" onClick={onCancel} className="button">
                Cancel
              </button>
            </div>

            {onDelete && (
              <div className="flex-grow text-right">
                <button type="button" onClick={onDelete} className="button">
                  Delete
                </button>
              </div>
            )}
          </div>

          <div className="sm:hidden">
            <button type="submit" className="primary-button w-full mb-4">
              Save
            </button>

            <button type="button" onClick={onCancel} className="button w-full mb-4">
              Cancel
            </button>

            {onDelete && (
              <div className="flex-grow text-right">
                <button type="button" onClick={onDelete} className="button w-full mb-4">
                  Delete
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    );
  }
}

TransactionForm.propTypes = {
  account: accountProp.isRequired,
  transaction: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.oneOf(['payment', 'deposit', 'transfer']),
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    payee: PropTypes.string,
    memo: PropTypes.string,
    budget_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    earmark_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    from_account_id: PropTypes.number,
    to_account_id: PropTypes.number,
    splits: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(['from', 'to']).isRequired,
        category_id: PropTypes.number,
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    )
  }),
  errors: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func
};

export default TransactionForm;
