import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SplitAmount from './SplitAmount';
import Currency from '../Currency';
import { Check, Square } from '../Icons';
import TransactionPayee from '../TransactionList/TransactionPayee';
import { formatShortDate } from '../../utils/format';
import accountProp from '../../propTypes/account';
import splitProp from '../../propTypes/split';
import { updateTransaction } from '../../requests/transactions';

const SplitListItem = ({ account, split }) => {
  const [cleared, setCleared] = useState(split.transaction.cleared);

  const toggleCleared = (event) => {
    event.preventDefault();
    event.stopPropagation();
    updateTransaction(split.transaction.id, { cleared: !cleared });
    setCleared(!cleared);
  };

  return (
    <div className="list-item">
      <Link to={`/accounts/${account.id}/transactions/${split.transaction.id}`}>
        <div className="transaction-row">
          <div className="cleared" onClick={toggleCleared}>
            {split.transaction.cleared ? (
              <Check className="mt-1 w-5 h-5 text-green-500" data-testid="cleared" />
            ) : (
              <Square className="mt-1 w-4 h-4 text-gray-300" data-testid="pending" />
            )}
          </div>

          <div className="date">{formatShortDate(split.transaction.date)}</div>

          <div className="payee">
            <TransactionPayee account={account} transaction={split.transaction} />
          </div>

          <div className="amount">
            <SplitAmount split={split} />
          </div>

          <div className="balance">
            <Currency amount={split.balance} />
          </div>
        </div>
      </Link>
    </div>
  );
};

SplitListItem.propTypes = {
  account: accountProp.isRequired,
  split: splitProp.isRequired
};

export default SplitListItem;
