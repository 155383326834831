import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Currency from '../Currency';
import accountProp from '../../propTypes/account';

const AccountMenuItem = ({ account, url, onClick }) => (
  <Link
    to={url}
    className="menu-item px-2 lg:px-4 flex text-gray-500 hover:text-gray-700 rounded-md lg:rounded-none"
    onClick={onClick}
  >
    <div className="flex-1">
      <p className="text-base truncate">{account.bank}</p>

      <div className="-mt-1">
        <h1 className="text-2xl lg:text-3xl truncate">{account.name}</h1>
      </div>
    </div>

    <div className="flex-1 text-right">
      <p className="text-base">Current Balance</p>

      <div className="-mt-1">
        <Currency amount={account.balance} className="text-2xl font-light lg:text-3xl" />
      </div>
    </div>
  </Link>
);

AccountMenuItem.propTypes = {
  account: accountProp.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default AccountMenuItem;
