import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import AppContext from '../../AppContext';
import { getLastAccount } from '../../localStorage';
import { getActiveAccounts } from '../../selectors/accounts';

const Accounts = () => {
  const { accounts } = useContext(AppContext);
  const activeAccounts = getActiveAccounts(accounts);
  const lastAccount = getLastAccount(activeAccounts);

  if (lastAccount) {
    return <Redirect to={`/accounts/${lastAccount.id}/transactions`} />;
  }

  return <Redirect to={`/accounts/new`} />;
};

export default Accounts;
