import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import AppContext from '../../AppContext';
import AccountHeader from '../../components/AccountHeader';
import CategoryForm from '../../components/CategoryForm';
import CategoryList from '../../components/CategoryList';
import Navbar from '../../components/Navbar';
import withAccount from '../../components/withAccount';
import accountProp from '../../propTypes/account';
import { createCategory } from '../../requests/categories';

const NewCategory = ({ account }) => {
  const [cancelled, setCancelled] = useState(false);
  const [created, setCreated] = useState();
  const [errors, setErrors] = useState({});
  const { refreshContext } = useContext(AppContext);

  const handleCancel = () => {
    setCancelled(true);
    setErrors({});
  };

  const handleSubmit = async (data) => {
    try {
      const category = await createCategory(data);
      await refreshContext();
      setCreated(category);
    } catch (error) {
      setErrors(error.response.data.errors);
    }
  };

  if (created) {
    return <Redirect to={`/accounts/${account.id}/categories/${created.id}`} />;
  }

  if (cancelled) {
    return <Redirect to={`/accounts/${account.id}`} />;
  }

  return (
    <>
      <Navbar account={account} />

      <AccountHeader account={account}>{(account) => `/accounts/${account.id}`}</AccountHeader>

      <hr />

      <div className="container">
        <div className="layout">
          <div>
            <CategoryList key={account.id} account={account}>
              {(category) => `/accounts/${account.id}/categories/${category.id}`}
            </CategoryList>
          </div>

          <div>
            <CategoryForm
              account={account}
              category={{ account_id: account.id, name: '' }}
              errors={errors}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
            />
          </div>
        </div>
      </div>
    </>
  );
};

NewCategory.propTypes = {
  account: accountProp.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired
    })
  })
};

export default withAccount(NewCategory);
