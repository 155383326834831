import PropTypes from 'prop-types';
import { withAllAccounts } from '../withAccounts';
import accountProp from '../../propTypes/account';
import transactionProp from '../../propTypes/transaction';

const TransactionPayee = ({ account, accounts, transaction }) => {
  const fromAccountID = transaction.from_account_id;
  const toAccountID = transaction.to_account_id;

  if (transaction.type === 'payment' || transaction.type === 'deposit') {
    return transaction.payee;
  }

  if (fromAccountID === account.id) {
    const toAccount = accounts.find((account) => toAccountID === account.id);
    return `Transfer to ${toAccount.name} - ${toAccount.bank}`;
  }

  const fromAccount = accounts.find((account) => fromAccountID === account.id);
  return `Transfer from ${fromAccount.name} - ${fromAccount.bank}`;
};

TransactionPayee.propTypes = {
  account: accountProp.isRequired,
  accounts: PropTypes.arrayOf(accountProp).isRequired,
  transaction: transactionProp.isRequired
};

export default withAllAccounts(TransactionPayee);
