import axios from 'axios';

export async function fetchEarmark(id) {
  const { data: earmark } = await axios.get(`/api/earmarks/${id}`);

  return earmark;
}

export async function fetchAccountEarmarks(account) {
  const { data: earmarks } = await axios.get(`/api/accounts/${account.id}/earmarks`);

  return earmarks;
}

export async function fetchCategoryEarmarks(category) {
  const { data: earmarks } = await axios.get(`/api/categories/${category.id}/earmarks`);

  return earmarks;
}

export async function createEarmark(params) {
  const { data: earmark } = await axios.post('/api/earmarks', { earmark: params });

  return earmark;
}

export async function updateEarmark(id, params) {
  const { data: earmark } = await axios.put(`/api/earmarks/${id}`, { earmark: params });

  return earmark;
}

export async function deleteEarmark(earmark) {
  await axios.delete(`/api/earmarks/${earmark.id}`);
}
