import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import classNames from 'classnames';
import { ChevronDown, X } from '../Icons';
import usePopup from '../usePopup';

const renderMenuItems = (items) => {
  if (items.length === 0) {
    return <div className="empty">No items match</div>;
  }

  return items;
};

const Combobox = ({ onChange, itemToString, initialSelection, errors, children }) => {
  const [inputRef, popupStyles, popupContainer] = usePopup();

  return (
    <Downshift onChange={onChange} itemToString={itemToString} initialSelectedItem={initialSelection}>
      {(downshiftProps) => {
        const { getInputProps, getToggleButtonProps, getMenuProps, isOpen, openMenu } = downshiftProps;

        return (
          <div className="relative">
            <input
              ref={inputRef}
              type="text"
              className={classNames('input', { 'input-error': errors })}
              {...getInputProps()}
              onFocus={openMenu}
            />
            <button
              type="button"
              className="w-6 h-6 absolute top-0 right-0 mt-2.5 mr-2 text-gray-500 focus:outline-none"
              {...getToggleButtonProps()}
              tabIndex="-1"
            >
              <ChevronDown className={`${isOpen ? 'hidden' : 'block'} w-6 h-6`} />

              <X className={`${isOpen ? 'block' : 'hidden'} w-6 h-6`} />
            </button>
            {isOpen &&
              ReactDOM.createPortal(
                <div className="popup" style={popupStyles}>
                  <div className="py-1" {...getMenuProps()}>
                    {renderMenuItems(children(downshiftProps))}
                  </div>
                </div>,
                popupContainer
              )}
          </div>
        );
      }}
    </Downshift>
  );
};

Combobox.propTypes = {
  onChange: PropTypes.func.isRequired,
  itemToString: PropTypes.func.isRequired,
  children: PropTypes.func.isRequired,
  initialSelection: PropTypes.any,
  errors: PropTypes.arrayOf(PropTypes.string)
};

export default Combobox;
