import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AccountBudgets from '../AccountBudgets';
import AccountTransactions from '../AccountTransactions';
import Accounts from '../Accounts';
import CategoryTransactions from '../CategoryTransactions';
import EditAccount from '../EditAccount';
import EditBudget from '../EditBudget';
import EditCategory from '../EditCategory';
import EditTransaction from '../EditTransaction';
import NewAccount from '../NewAccount';
import NewBudget from '../NewBudget';
import NewCategory from '../NewCategory';
import NewTransaction from '../NewTransaction';
import Login from '../Login';
import Logout from '../Logout';
import AppContext from '../../AppContext';
import ScrollTop from '../../components/ScrollTop';
import { fetchAccounts } from '../../requests/accounts';
import { fetchCategories } from '../../requests/categories';

const App = () => {
  const [accounts, setAccounts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const refreshContext = async () => {
    const [accounts, categories] = await Promise.all([fetchAccounts(), fetchCategories()]);
    setAccounts(accounts);
    setCategories(categories);
    setLoading(false);
  };

  if (!['/login', 'logout'].includes(window.location.pathname)) {
    useEffect(refreshContext, []);

    if (loading) {
      return null;
    }
  }

  return (
    <AppContext.Provider value={{ accounts, categories, refreshContext }}>
      <Router>
        <ScrollTop />
        <Switch>
          <Route exact path="/" component={Accounts} />
          <Route exact path="/accounts/new" component={NewAccount} />
          <Route exact path="/accounts/:id" component={EditAccount} />
          <Route exact path="/accounts/:id/categories/new" component={NewCategory} />
          <Route exact path="/accounts/:accountId/categories/:id" component={EditCategory} />
          <Route exact path="/accounts/:id/transactions" component={AccountTransactions} />
          <Route exact path="/accounts/:id/transactions/new" component={NewTransaction} />
          <Route exact path="/accounts/:accountId/transactions/:id" component={EditTransaction} />
          <Route exact path="/accounts/:accountId/categories/:id/transactions" component={CategoryTransactions} />
          <Route exact path="/accounts/:id/budgets" component={AccountBudgets} />
          <Route exact path="/accounts/:id/budgets/new" component={NewBudget} />
          <Route exact path="/accounts/:accountId/budgets/:id" component={EditBudget} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/logout" component={Logout} />
        </Switch>
      </Router>
    </AppContext.Provider>
  );
};

export default App;
