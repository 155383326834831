const AUTH_TOKEN = 'auth_token';
const LAST_ACCOUNT = 'last_account';

export const getAuthToken = () => localStorage.getItem(AUTH_TOKEN);

export const setAuthToken = (token) => localStorage.setItem(AUTH_TOKEN, token);

export const getLastAccount = (accounts) => {
  const id = localStorage.getItem(LAST_ACCOUNT);

  if (id) {
    const account = accounts.find((account) => account.id.toString() === id);

    if (account) {
      return account;
    }
  }

  if (accounts.length > 0) {
    return accounts[0];
  }

  return null;
};

export const setLastAccount = (account) => localStorage.setItem(LAST_ACCOUNT, account.id.toString());
