import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Error from '../Error';
import { Plus } from '../Icons';
import accountProp from '../../propTypes/account';

const AccountForm = ({ errors, onCancel, onDelete, onSubmit, ...rest }) => {
  const [account, setAccount] = useState(rest.account);

  const handleCancel = () => {
    onCancel && onCancel();
    setAccount(rest.account);
  };

  const handleChange = (changes) => setAccount({ ...account, ...changes });

  const handleSubmit = (event) => {
    event.preventDefault();
    const { name, bank } = account;
    onSubmit({ name, bank });
  };

  return (
    <div>
      <div className="flex">
        <div className="flex-1">
          <h2 className="text-2xl text-gray-700 pb-2">{account.id ? 'Edit' : 'New'} Account</h2>
        </div>

        <div className="flex-grow-0">
          <Link to="/accounts/new" className="primary-button px-2 py-1 mr-2 flex items-center justify-center">
            <Plus className="inline h-6 w-6" />
          </Link>
        </div>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="w-full mb-6">
          <label htmlFor="account_name" className="label">
            Name
          </label>

          <input
            id="account_name"
            type="text"
            className={classNames('input', { 'input-error': errors.name })}
            value={account.name}
            onChange={(event) => handleChange({ name: event.target.value })}
          />

          <Error errors={errors.name} prefix="Name" />
        </div>

        <div className="w-full mb-6">
          <label htmlFor="account_bank" className="label">
            Bank
          </label>

          <input
            id="account_bank"
            type="text"
            className={classNames('input', { 'input-erorr': errors.bank })}
            value={account.bank}
            onChange={(event) => handleChange({ bank: event.target.value })}
          />

          <Error errors={errors.bank} prefix="Bank" />
        </div>

        <div className="flex mb-9">
          <div className="flex-none">
            <button type="submit" className="primary-button">
              Save
            </button>

            <button type="button" onClick={handleCancel} className="button">
              Cancel
            </button>
          </div>

          {onDelete && (
            <div className="flex-grow text-right">
              <button type="button" onClick={onDelete} className="button">
                Delete
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

AccountForm.propTypes = {
  account: accountProp.isRequired,
  errors: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func
};

export default AccountForm;
