import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavMenuLink = ({ to, children }) => (
  <Link to={to} className="nav-menu-link">
    {children}
  </Link>
);

NavMenuLink.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default NavMenuLink;
