import React from 'react';
import PropTypes from 'prop-types';

const Error = ({ errors, prefix }) => {
  if (errors) {
    return <p className="text-red-500">{errors.map((error) => `${prefix} ${error}`).join(', ')}</p>;
  }

  return null;
};

Error.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
  prefix: PropTypes.string.isRequired
};

export default Error;
