import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavLink from './NavLink';
import NavMenuLink from './NavMenuLink';
import { Menu, X } from '../Icons';
import accountProp from '../../propTypes/account';

const Navbar = ({ account }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <nav>
        <div className="container pb-0">
          <div className="relative flex items-center justify-between h-16">
            <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
              <button
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-500 hover:text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                aria-expanded={open}
                onClick={() => setOpen(!open)}
              >
                <span className="sr-only">Open main menu</span>

                <Menu className={`${open ? 'hidden' : 'block'} h-6 w-6`} aria-hidden={open} />

                <X className={`${open ? 'block' : 'hidden'} h-6 w-6`} aria-hidden={!open} />
              </button>
            </div>

            <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/" className="logo text-green-500 hover:text-green-600">
                  Expenditure
                </Link>
              </div>

              {account && (
                <div className="flex-grow hidden sm:block sm:ml-6 sm:mt-2">
                  <div className="flex justify-end space-x-4">
                    <NavLink to={`/accounts/${account.id}/transactions`}>Transactions</NavLink>

                    <NavLink to={`/accounts/${account.id}/budgets`}>Budgets</NavLink>

                    <NavLink to={`/accounts/${account.id}`}>Profile</NavLink>

                    <NavMenuLink to="/logout">Logout</NavMenuLink>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {account && (
          <div className={`${open ? 'block' : 'hidden'} sm:hidden`}>
            <div className="px-2 pb-3 space-y-1">
              <NavMenuLink to={`/accounts/${account.id}/transactions`}>Transactions</NavMenuLink>

              <NavMenuLink to={`/accounts/${account.id}/budgets`}>Budgets</NavMenuLink>

              <NavMenuLink to={`/accounts/${account.id}`}>Profile</NavMenuLink>

              <NavMenuLink to="/logout">Logout</NavMenuLink>
            </div>
          </div>
        )}
      </nav>

      <hr className="lg:hidden pb-4" />
    </>
  );
};

Navbar.propTypes = {
  account: accountProp
};

export default Navbar;
