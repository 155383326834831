import React from 'react';
import { Link } from 'react-router-dom';
import Currency from '../Currency';
import accountProp from '../../propTypes/account';
import budgetProp from '../../propTypes/budget';
import { formatShortDate } from '../../utils/format';

const BudgetListItem = ({ account, budget }) => (
  <div className="px-2 py-1 rounded-md text-gray-500 hover:bg-gray-100 hover:text-gray-700">
    <Link to={`/accounts/${account.id}/budgets/${budget.id}`}>
      <div className="grid grid-cols-3 gap-x-4">
        <div>{formatShortDate(budget.start_date)}</div>

        <div>{formatShortDate(budget.end_date)}</div>

        <div className="text-right">
          <Currency amount={budget.amount} />
        </div>
      </div>
    </Link>
  </div>
);

BudgetListItem.propTypes = {
  account: accountProp.isRequired,
  budget: budgetProp.isRequired
};

export default BudgetListItem;
