/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { useContext, useEffect, useState } from 'react';
import AppContext from '../AppContext';
import { setLastAccount } from '../localStorage';
import { getActiveAccounts } from '../selectors/accounts';

const withAccount = (WrappedComponent, key = 'id') => {
  return (props) => {
    const { accounts } = useContext(AppContext);
    const [account, setAccount] = useState();
    const {
      match: { params }
    } = props;

    useEffect(() => {
      const activeAccounts = getActiveAccounts(accounts);
      const account = activeAccounts.find((account) => account.id.toString() === params[key]);
      setLastAccount(account);
      setAccount(account);
    }, [params[key]]);

    if (account) {
      return <WrappedComponent account={account} {...props} />;
    }

    return null;
  };
};

export default withAccount;
