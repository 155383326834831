import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import EarmarkForm from './EarmarkForm';
import EarmarkList from './EarmarkList';
import Modal, { ModalBody, ModalFooter } from '../Modal';
import AppContext from '../../AppContext';
import accountProp from '../../propTypes/account';
import categoryProp from '../../propTypes/category';
import earmarkProp from '../../propTypes/earmark';
import { getActiveAccountCategories } from '../../selectors/categories';

const EarmarkDialog = ({ account, categories, earmarks, onClose, onUpdate, onRemove }) => {
  const [editing, setEditing] = useState();

  const handleUpdate = (changes) => setEditing({ ...editing, ...changes });

  const handleSave = async () => {
    await onUpdate(editing);
    setEditing(null);
  };

  return (
    <Modal>
      <ModalBody>
        <h3 className="text-xl text-gray-700 pb-2">{account.name} Earmarks</h3>

        {editing ? (
          <EarmarkForm earmark={editing} categories={categories} onUpdate={handleUpdate} />
        ) : (
          <React.Fragment>
            {earmarks.length === 0 && <p className="empty">No earmarks.</p>}

            <EarmarkList
              account={account}
              categories={categories}
              earmarks={earmarks}
              onEdit={(earmark) => setEditing(earmark)}
              onRemove={onRemove}
            />
          </React.Fragment>
        )}
      </ModalBody>

      <ModalFooter>
        {editing ? (
          <div className="text-right">
            <button type="submit" className="primary-button" onClick={handleSave}>
              Save
            </button>

            <button type="button" onClick={() => setEditing(null)} className="button">
              Cancel
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-2">
            <div>
              <button type="button" className="button" onClick={() => setEditing({ payee: '', amount: 0 })}>
                Add Earmark
              </button>
            </div>

            <div className="text-right">
              <button type="button" className="button mr-0" onClick={onClose}>
                Done
              </button>
            </div>
          </div>
        )}
      </ModalFooter>
    </Modal>
  );
};

EarmarkDialog.propTypes = {
  account: accountProp.isRequired,
  categories: PropTypes.arrayOf(categoryProp).isRequired,
  earmarks: PropTypes.arrayOf(earmarkProp).isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired
};

// eslint-disable-next-line react/display-name
export default (props) => {
  // eslint-disable-next-line react/prop-types
  const { account } = props;
  const { categories } = useContext(AppContext);
  return <EarmarkDialog categories={getActiveAccountCategories(account, categories)} {...props} />;
};
