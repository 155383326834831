import React from 'react';
import PropTypes from 'prop-types';
import AmountInput from '../AmountInput';
import CategoryCombobox from '../CategoryCombobox';
import Error from '../Error';
import { X } from '../Icons';
import categoryProp from '../../propTypes/category';

const SplitForm = ({ categories, split, onChange, onRemove, single, errors }) => {
  const { category_id: categoryId, amount } = split;
  const category = categories.find((category) => category.id === categoryId);

  const handleCategoryChange = (category) => onChange({ ...split, category_id: category.id });
  const handleAmountChange = (amount) => onChange({ ...split, amount });

  return (
    <div className="grid grid-cols-3 gap-2 sm:gap-6 mb-6 sm:mb-3">
      <div className={`col-span-3 sm:${single ? 'col-span-3' : 'col-span-2'}`}>
        <CategoryCombobox
          categories={categories}
          category={category}
          onChange={handleCategoryChange}
          errors={errors.category_id}
        />

        <Error errors={errors.category_id} prefix="Category" />
      </div>

      {!single && (
        <div className="col-span-3 sm:col-span-1 flex">
          <div className="flex-1">
            <AmountInput amount={amount} onChange={handleAmountChange} errors={errors.amount} />

            <Error errors={errors.amount} prefix="Account" />
          </div>

          <div className="flex-grow-0">
            <button type="button" onClick={onRemove} className="icon-button">
              <X className="w-6 h-6" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

SplitForm.propTypes = {
  categories: PropTypes.arrayOf(categoryProp).isRequired,
  split: PropTypes.shape({
    category_id: PropTypes.number,
    amount: PropTypes.number
  }),
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  single: PropTypes.bool,
  errors: PropTypes.object.isRequired
};

export default SplitForm;
