import accounting from 'accounting';

accounting.settings.currency.format = {
  pos: '%s%v',
  neg: '(%s%v)',
  zero: '%s%v'
};

accounting.settings.number.precision = 2;

export const formatAmount = (amount) => {
  if (amount) {
    return accounting.formatNumber(amount);
  }

  return '';
};

export const formatMoney = (amount) => accounting.formatMoney(amount);

const isoDateRegex = /(?<century>\d\d)(?<decade>\d)(?<year>\d)-(?<month>\d\d?)-(?<day>\d\d?)/;
const formattedDateRegex = /(?<month>\d\d?)[/-](?<day>\d\d?)[/-](?<century>\d\d)?(?<decade>\d)(?<year>\d)/;

export const parseDate = (value) => {
  if (typeof value === 'object') {
    return value;
  }

  if (typeof value === 'string') {
    const matches = value.match(isoDateRegex) || value.match(formattedDateRegex);

    if (matches) {
      const century = matches.groups.century ? parseInt(matches.groups.century, 10) : 20;
      const decade = parseInt(matches.groups.decade, 10);
      const year = parseInt(matches.groups.year, 10);
      const month = parseInt(matches.groups.month, 10) - 1;
      const day = parseInt(matches.groups.day, 10);
      return new Date(century * 100 + decade * 10 + year, month, day);
    }
  }

  return new Date(value);
};

export const formatDate = (date) => {
  if (date) {
    date = parseDate(date);

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month}/${day}/${year.toString().substring(2, 4)}`;
  }
};

export const formatShortDate = (date) => {
  if (date) {
    date = parseDate(date);

    const year = date.getFullYear();
    const currentYear = new Date().getFullYear();

    if (year === currentYear) {
      const month = date.getMonth() + 1;
      const day = date.getDate();

      return `${month}/${day}`;
    }

    return formatDate(date);
  }
};

export const formatISODate = (date) => {
  if (date) {
    date = parseDate(date);

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    return `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
  }
};
