import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import accounting from 'accounting';
import classNames from 'classnames';
import { formatAmount } from '../../utils/format';

const parseAmount = (value) => {
  value = accounting.unformat(value);

  if (value > 0) {
    return value;
  }
};

const AmountInput = ({ amount, errors, onChange, ...rest }) => {
  const [value, setValue] = useState('');
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    if (focused) {
      setValue(amount ? amount / 100 : '');
    } else {
      setValue(formatAmount(amount / 100));
    }
  }, [focused]);

  useEffect(() => {
    if (value === '' && amount) {
      setValue(formatAmount(amount / 100));
    }
  }, [amount]);

  const handleFocus = () => setFocused(true);

  const handleBlur = () => setFocused(false);

  const handleChange = (event) => {
    const newValue = event.target.value;
    let newAmount = parseAmount(newValue);

    if (newAmount) {
      newAmount = Math.round(newAmount.toFixed(2) * 100);
    }

    setValue(newValue);
    onChange(newAmount);
  };

  return (
    <input
      {...rest}
      type="text"
      value={value}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      className={classNames('input', { 'input-error': errors })}
    />
  );
};

AmountInput.propTypes = {
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired
};

export default AmountInput;
