import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import BudgetListItem from './BudgetListItem';
import { Plus } from '../Icons';
import accountProp from '../../propTypes/account';
import { fetchAccountBudgets } from '../../requests/budgets';

const BudgetList = ({ account, fetchAccountBudgets }) => {
  const [budgets, setBudgets] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  useEffect(async () => {
    const { items, total_count: totalCount } = await fetchAccountBudgets(account, page);
    setHasMore(totalCount > budgets.length + items.length);
    setBudgets(budgets.concat(items));
  }, [account.id, page]);

  const nextPage = useCallback(() => setPage(page + 1));

  return (
    <div>
      <div className="flex">
        <div className="flex-1">
          <h2 className="text-2xl text-gray-700 pb-2">Budgets</h2>
        </div>

        <div className="flex-grow-0">
          <Link
            to={`/accounts/${account.id}/budgets/new`}
            className="primary-button px-2 py-1 mr-2 flex items-center justify-center"
          >
            <Plus className="inline h-6 w-6" />
          </Link>
        </div>
      </div>

      <div className="grid grid-cols-3 gap-x-4">
        <div className="font-medium text-gray-700">From</div>

        <div className="font-medium text-gray-700">To</div>

        <div className="font-medium text-gray-700 pr-2 text-right">Amount</div>
      </div>

      {budgets.length === 0 && <p className="empty">No budgets.</p>}

      {budgets.map((budget) => (
        <BudgetListItem key={budget.id} account={account} budget={budget} />
      ))}

      {hasMore && (
        <div className="mt-9 mb-9 text-center">
          <button type="button" className="button" onClick={nextPage}>
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

BudgetList.propTypes = {
  account: accountProp.isRequired,
  fetchAccountBudgets: PropTypes.func.isRequired
};

BudgetList.defaultProps = {
  fetchAccountBudgets
};

export default BudgetList;
