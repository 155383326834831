import axios from 'axios';

export async function fetchCategories() {
  const { data: categories } = await axios.get('/api/categories');

  return categories;
}

export async function createCategory(params) {
  const { data: category } = await axios.post('/api/categories', { category: params });

  return category;
}

export async function updateCategory(id, params) {
  const { data: category } = await axios.put(`/api/categories/${id}`, { category: params });

  return category;
}

export async function deleteCategory(category) {
  await axios.delete(`/api/categories/${category.id}`);
}
