import axios from 'axios';

export async function fetchBudget(id) {
  const { data: budget } = await axios.get(`/api/budgets/${id}`);

  return budget;
}

export async function fetchAccountBudgets(account, page, params = {}) {
  const { data: budgets } = await axios.get(`/api/accounts/${account.id}/budgets`, {
    params: { ...params, page }
  });

  return budgets;
}

export async function createBudget(params) {
  const { data: budget } = await axios.post('/api/budgets', { budget: params });

  return budget;
}

export async function updateBudget(id, params) {
  const { data: budget } = await axios.put(`/api/budgets/${id}`, { budget: params });

  return budget;
}

export async function deleteBudget(budget) {
  await axios.delete(`/api/budgets/${budget.id}`);
}
