import React from 'react';
import PropTypes from 'prop-types';
import AccountMenuItem, { accountToString } from './AccountMenuItem';
import Combobox from '../Combobox';
import withAccounts from '../withAccounts';
import accountProp from '../../propTypes/account';

const filterAccounts = (inputValue, accounts) => {
  if (!inputValue) {
    return accounts;
  }

  const exactMatch = accounts.find((account) => accountToString(account) === inputValue);

  if (exactMatch) {
    return accounts;
  }

  return accounts.filter(
    (account) => !inputValue || accountToString(account).toLowerCase().includes(inputValue.toLowerCase())
  );
};

const AccountCombobox = ({ account, accounts, errors, onChange }) => (
  <Combobox
    key={account && account.id}
    onChange={onChange}
    itemToString={accountToString}
    initialSelection={account}
    errors={errors}
  >
    {({ highlightedIndex, getItemProps, inputValue }) =>
      filterAccounts(inputValue, accounts).map((account, index) => (
        <AccountMenuItem
          key={account.id}
          account={account}
          index={index}
          highlightedIndex={highlightedIndex}
          getItemProps={getItemProps}
        />
      ))
    }
  </Combobox>
);

AccountCombobox.propTypes = {
  account: accountProp,
  accounts: PropTypes.arrayOf(accountProp).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired
};

export default withAccounts(AccountCombobox);
