import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import TransactionAmount from './TransactionAmount';
import TransactionBalance from './TransactionBalance';
import TransactionPayee from './TransactionPayee';
import TransactionSplits from './TransactionSplits';
import { Check, Square } from '../Icons';
import accountProp from '../../propTypes/account';
import transactionProp from '../../propTypes/transaction';
import { updateTransaction } from '../../requests/transactions';
import { formatShortDate } from '../../utils/format';

const TransactionListItem = ({ account, transaction }) => {
  const [cleared, setCleared] = useState(transaction.cleared);

  const toggleCleared = (event) => {
    event.preventDefault();
    event.stopPropagation();
    updateTransaction(transaction.id, { cleared: !cleared });
    setCleared(!cleared);
  };

  return (
    <div className="list-item">
      <Link to={`/accounts/${account.id}/transactions/${transaction.id}`}>
        <div className="transaction-row">
          <div className="cleared" onClick={toggleCleared}>
            {cleared ? (
              <Check className="mt-1 w-5 h-5 text-green-500 cursor-pointer" data-testid="cleared" />
            ) : (
              <Square className="mt-1 w-4 h-4 text-gray-300 cursor-pointer" data-testid="pending" />
            )}
          </div>

          <div className="date">{formatShortDate(transaction.date)}</div>

          <div className="payee">
            <TransactionPayee account={account} transaction={transaction} />
          </div>

          <div className="amount">
            <TransactionAmount account={account} transaction={transaction} />
          </div>

          <div className="splits">
            <TransactionSplits account={account} transaction={transaction} />
          </div>

          <div className="balance">
            <TransactionBalance account={account} transaction={transaction} />
          </div>
        </div>
      </Link>
    </div>
  );
};

TransactionListItem.propTypes = {
  account: accountProp.isRequired,
  transaction: transactionProp.isRequired
};

export default TransactionListItem;
