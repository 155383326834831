import React from 'react';
import PropTypes from 'prop-types';
import { formatMoney } from '../../utils/format';

const Currency = ({ amount, className }) => {
  const formattedAmount = formatMoney(amount / 100);
  let classNames = [];

  if (className) {
    classNames.push(className);
  }

  if (amount < 0) {
    classNames.push('text-red-500');
  }

  return <span className={classNames.join(' ')}>{formattedAmount}</span>;
};

Currency.propTypes = {
  amount: PropTypes.number.isRequired,
  className: PropTypes.string
};

export default Currency;
